import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { hl7Instance } from "../../api/axios";
import { toTitleCase, formatDateToDDMMYY } from "../../utils/Utils";

const ProblemComponent = ({ selectedTabKey }) => {
  const [problems, setProblems] = useState([]); // Store paginated records
  const [loading, setLoading] = useState(false);
  const [nextLink, setNextLink] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [totalRecords, setTotalRecords] = useState(0); // Store total available records
  const [currentPage, setCurrentPage] = useState(1);
  const [urlStack, setUrlStack] = useState([""]);

  const ITEMS_PER_PAGE = 10;

  const memberID = useSelector(
    (state) => state?.clinicalInfo?.memberData?.memberID
  );
  const token = localStorage.getItem("PortalAdmin-AccessToken");

  const fetchProblemsByPage = async (
    page,
    queryString = null,
    isPrevious = false,
    searchText = ""
  ) => {
    setLoading(true);
    try {
      const query = queryString?.split("&")?.[1];
      const textParam = searchText?.trim()
        ? `&code:text=${encodeURIComponent(searchText.trim())}`
        : "";
      const pageUrl = `/Condition?patient=${memberID}${textParam}${
        query ? "&" + query : ""
      }`;

      const response = await hl7Instance.get(pageUrl, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const data = response.data;

      if (!data.entry || data.entry.length === 0) {
        console.warn("No data received for search query:", searchText);
        setProblems([]);
        setTotalRecords(0);
        return;
      }

      const nextLink = data.link.find((l) => l.relation === "next")?.url;
      const currentLink =
        data.link.find((l) => l.relation === "self")?.url ?? "";

      if (!isPrevious) {
        setUrlStack((prevUrls) => [...prevUrls, currentLink]);
      }

      setProblems(data.entry);
      setNextLink(() => nextLink);

      if (data.total) {
        setTotalRecords(data.total);
      }

      setCurrentPage(page);
    } catch (error) {
      console.error("Error fetching problems:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedTabKey === "problems" && memberID) {
      fetchProblemsByPage(1);
    }
  }, [selectedTabKey, memberID]);

  // const handleSearch = () => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     const query = searchQuery.trim().replace(/\s+/g, " ").toLowerCase();

  //     if (!query) {
  //       fetchProblemsByPage(1,null, false, query);
  //     } else {
  //       const filtered = problems.filter((entry) => {
  //         const problemName = entry.resource?.code?.coding?.[0]?.display || "";
  //         return problemName.toLowerCase().includes(query);
  //       });

  //       setProblems(filtered);
  //     }

  //     setCurrentPage(1);
  //     setLoading(false);
  //   }, 500);
  // };
  // const handleSearch = () => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     const query = searchQuery.trim().replace(/\s+/g, " ").toLowerCase();

  //     // Always call the API with search query (even if empty)
  //     fetchProblemsByPage(1, null, false, query);

  //     setCurrentPage(1);
  //     setLoading(false);
  //   }, 500);
  // };

  const handleSearch = () => {
    const query = searchQuery.trim();

    if (query.length > 0) {
      fetchProblemsByPage(1, null, false, query);
    } else {
      fetchProblemsByPage(1);
    }
  };

  const handleClear = () => {
    setSearchQuery("");
    setCurrentPage(1);
    fetchProblemsByPage(1);
  };

  const fetchNextPage = () => {
    if (currentPage * ITEMS_PER_PAGE < totalRecords) {
      fetchProblemsByPage(currentPage + 1, nextLink);
    }
  };

  const fetchPreviousPage = () => {
    if (urlStack.length > 1) {
      const previousLink = urlStack[urlStack.length - 2];

      if (currentPage > 1) {
        setUrlStack((prevUrls) => prevUrls.slice(0, -1));
        fetchProblemsByPage(currentPage - 1, previousLink, true);
      }
    }
  };

  return (
    <>
      <div className="card-form form-content mt-0">
        <div className="row">
          <div className="col-sm-6 col-md-6 col-xl-4 col-xxl-3 mb-4 mb-sm-0">
            <label htmlFor="searchprocedure" className="d-flex form-label">
              Problem Name
            </label>
            <div className="form-field position-relative">
              <input
                id="searchprocedure"
                type="text"
                className="form-control h-48"
                placeholder="Search for a Problem"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <span className="field-icon position-absolute top-50 translate-middle-y">
                <i className="fh_search"></i>
              </span>
            </div>
          </div>
          <div className="d-flex align-items-sm-end justify-content-center col-12 col-sm-auto">
            <button
              type="button"
              className="btn btn-primary h-48 me-3"
              onClick={handleSearch}
            >
              <span>Search</span>
            </button>
            <button
              type="button"
              className="btn btn-border-primary h-48"
              onClick={handleClear}
            >
              <span>Clear</span>
            </button>
          </div>
        </div>
      </div>

      <div className="card-content">
        <div className="content-subheader d-flex align-items-center">
          <h2 className="page-subheader me-3">Problem List</h2>
          <span className="total-record">
            [ Total Records: {totalRecords} ]
          </span>
        </div>

        <PerfectScrollbar className="table-responsive mb-2">
          <table className="table">
            <thead className="sticky-top">
              <tr>
                <th>Problem Name</th>
                <th>Status</th>
                <th className="m-w-150">Recorded Date</th>
                <th>Provider</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={4} className="text-center my-4">
                    <div className="spinner-border" role="status"></div>
                  </td>
                </tr>
              ) : problems.length > 0 ? (
                problems.map((entry, index) => (
                  <tr key={index}>
                    <td>{entry.resource?.code?.coding?.[0]?.display || "-"}</td>
                    <td>
                      {toTitleCase(
                        entry.resource?.clinicalStatus?.coding?.[0]?.code
                      ) || "-"}
                    </td>
                    <td>
                      {formatDateToDDMMYY(entry.resource?.recordedDate) || "-"}
                    </td>
                    <td>
                      {entry.resource?.recorder?.display ||
                        entry.resource?.asserter?.display ||
                        "-"}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="text-center">
                    No Data Available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </PerfectScrollbar>

        <div className="d-flex justify-content-end">
          <nav
            aria-label="Page navigation"
            className="d-flex align-items-center"
          >
            <span className="pagination-info me-2">Page</span>
            <ul className="pagination d-flex mb-0">
              <li className="page-item">
                <button
                  className="page-link"
                  disabled={currentPage === 1}
                  onClick={fetchPreviousPage}
                >
                  <i className="fh_arrow_left_line"></i>
                </button>
              </li>
              <li className="page-item">
                <button className="page-link current-page">
                  {currentPage || 1}
                </button>
              </li>
              <li className="page-item">
                <button
                  className="page-link me-0"
                  disabled={currentPage * ITEMS_PER_PAGE >= totalRecords}
                  onClick={fetchNextPage}
                >
                  <i className="fh_arrow_right_line"></i>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default ProblemComponent;
