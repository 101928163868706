import { urls } from "../Urls";
import { Http } from "../Http";
import { ClinicalInfo } from "../ClinicalService";

export class ClicialInfoAPI {
  static getProceduralCalim(id: string, count: number) {
    const url = urls.clinicalInformation.getProceduralClaims(id, count);
    return ClinicalInfo.get(url);
  }

  static getProblemsClaims(id: string, count: number) {
    const url = urls.clinicalInformation.getProblemClaims(id, count);
    return ClinicalInfo.get(url);
  }

  static getcarePlanClaim(id: string, category: string) {
    const url = urls.clinicalInformation.getCarePlanClaims(id, category);
    return ClinicalInfo.get(url);
  }

  static getMedicationRequest(id: string, atex: string) {
    const url = urls.clinicalInformation.getMedicationRequests(id,atex);
    return ClinicalInfo.get(url);
  }
}
