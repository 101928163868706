import { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { hl7Instance, blueButtonData } from "../../api/axios";
import { UserManager } from "oidc-client";
import oidcConfig from "../../config/oidc-config";
import { useAuth } from "../../context/auth-context";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./claims.scss";
import PerfectScrollbar from "react-perfect-scrollbar";
import { urls } from "../../api/Urls";
import { Http } from "../../api/Http";
import {
  toTitleCase,
  formatCurrency,
  formatDateToDDMMYY,
} from "../../utils/Utils";
import { PreviousInsuranceApi } from "../../api/services/PrevInsuranceService";
import { useDispatch } from "react-redux";
import { getBBPatientData } from "../../redux/slice/BlueButtonSlice";
import { BlueButtonApi } from "../../api/services/BlueButtonService";
const userManager = new UserManager(oidcConfig);

async function getAccessToken() {
  try {
    const user = await userManager.getUser();
    if (!user) {
      throw new Error("User is not authenticated");
    }
    return user.access_token;
  } catch (error) {
    throw error;
  }
}
async function getDynamicPatientInfo() {
  let accessToken;
  let ImpersonateUserID = localStorage.getItem("ImpersonateUserID");
  let userSub = "";
  const PortalToken = localStorage.getItem("PortalAdmin-AccessToken");
  if (ImpersonateUserID) {
    userSub = ImpersonateUserID;
    accessToken = PortalToken;
  } else {
    accessToken = await getAccessToken();
    const tokenPayload = JSON.parse(atob(accessToken.split(".")[1]));
    userSub = tokenPayload.sub;
  }

  const patientInfoResponse = await PreviousInsuranceApi.getBBMemberDetails(
    userSub
  );
  //setUpdateData(patientInfoResponse)
  return {
    patientID: patientInfoResponse.memberID,
    bB_MemberID: patientInfoResponse.bB_MemberID,
    isActive: patientInfoResponse.bB_IsActive,
    bB_RefreshToken: patientInfoResponse.bB_RefreshToken,
    ssoGuid: patientInfoResponse.ssoGuid,
  };
}

const Claim = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [payerClaims, setPayerClaims] = useState([]);
  const { blueButtonToken, setBlueButtonToken } = useAuth();
  const [claims, setClaims] = useState([]);
  const [nextFhirUrl, setNextFhirUrl] = useState("");
  const [payerListData, SetPayerListData] = useState([]);
  const [searchParams, setSearchParams] = useState({
    patientId: localStorage.getItem("patientId") || "",
    fromDate: "",
    toDate: "",
  });
  const [isFromBlueButton] = useState(
    location.state?.fromBlueButton || location.pathname.includes("bluebutton")
  );

  const [isFromDetail, setIsFromDetail] = useState(location.state?.fromDetail);

  const savedSource =
    localStorage.getItem("selectedSource") ||
    (isFromBlueButton ? "CMS" : "Healthplan");
  const [source, setSource] = useState(savedSource);

  const [newSource, setNewSource] = useState("");
  const [isActiveCMS, setIsActiveCMS] = useState(isFromBlueButton);
  const [selectedPatient, setSelectedPatient] = useState("");
  const [page] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalClaims, setTotalClaims] = useState(0);
  const [setNextUrl] = useState("");
  const [setPrevUrls] = useState([]);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [userSsoId, setUserSsoId] = useState("");
  const [selectedPayerID, setSelectedPayerID] = useState(null);
  const [paginationUrls, setPaginationUrls] = useState({
    current: "",
    next: "",
    prev: "",
  });
  const [isSourceReady, setIsSourceReady] = useState(false);

  const initialUrl = "";
  const [urlStack, setUrlStack] = useState([initialUrl]);
  const prevPageRef = useRef();
  const [tempDates, setTempDates] = useState({
    fromDate: null,
    toDate: null,
  });
  const [isSourceLoading, setIsSourceLoading] = useState(true);

  const fetchPatientData = async () => {
    try {
      let accessToken;
      let ImpersonateUserID = localStorage.getItem("ImpersonateUserID");
      let userSub = "";
      const PortalToken = localStorage.getItem("PortalAdmin-AccessToken");

      // Use ImpersonateUserID if available, else get the access token
      if (ImpersonateUserID) {
        userSub = ImpersonateUserID;
        accessToken = PortalToken;
      } else {
        // Fetch access token from the getAccessToken function
        accessToken = await getAccessToken();

        const tokenPayload = JSON.parse(atob(accessToken.split(".")[1]));

        // Assign 'sub' claim from token payload as userSub
        userSub = tokenPayload.sub;
      }

      // Call the API with userSub and the Bearer token
      const patientIdResponse =
        source !== "CMS"
          ? await PreviousInsuranceApi.getBBMemberDetails(userSub)
          : await BlueButtonApi.GetBlueButtonData(userSub);
      const memberData = patientIdResponse;
      return {
        MemberID: memberData?.memberID,
        BB_MemberID: memberData?.bbMemberID,
      };
    } catch (error) {
      console.error("Error in fetching patient data: ", error);
      throw error;
    }
  };

  const handleFromDateChange = (date) => {
    setTempDates((prevDates) => ({
      ...prevDates,
      fromDate: date,
    }));
  };

  const handleToDateChange = (date) => {
    setTempDates((prevDates) => ({
      ...prevDates,
      toDate: date,
    }));
  };

  const handleSearch = () => {
    // Ensure that fromDate and toDate are valid Date objects or handle them as needed
    const formattedFromDate = tempDates.fromDate
      ? formatDateForQuery(tempDates.fromDate)
      : "";
    const formattedToDate = tempDates.toDate
      ? formatDateForQuery(tempDates.toDate)
      : "";

    setSearchParams({
      ...searchParams,
      fromDate: formattedFromDate,
      toDate: formattedToDate,
    });

    if (source || newSource) {
      fetchClaims({
        fromDate: formattedFromDate,
        toDate: formattedToDate,
      });
    }
  };

  const fetchPayerList = async (payerName, id, page, pageSize, sortColumn) => {
    try {
      const response = await Http.get(
        urls.previousInsurance.getListData(
          payerName,
          id,
          page,
          pageSize,
          sortColumn
        )
      );
      if (response?.items?.length) {
        const payerNameList = response?.items?.map((item) => ({
          id: item.id,
          payerName: item?.payerName,
          payerID: item.payerID,
          memberID: item.payerMemberID,
          isActive: item.isActive,
          ssoID: item.ssoUserID,
          payerMemberID: item.payerMemberID,
        }));
        SetPayerListData(payerNameList); // Set only the payerNameList
      }
    } catch (error) {
      console.error("Error fetching grid data:", error);
    } finally {
      setLoading(false); // Set loading to false after the API call completes
    }
  };
  const getDynamicInfo = () => {
    getDynamicPatientInfo().then((dynamicPatientInfo) => {
      setSearchParams((prevState) => ({
        ...prevState,
        patientId: dynamicPatientInfo.patientID,
      }));

      dynamicPatientInfo.isActive === "True" && setIsActiveCMS(true);
    });
  };

  useEffect(() => {
    if (isFromBlueButton) {
      setSource("CMS");
      getDynamicInfo();
      setIsActiveCMS(true);
      //setIsFromBlueButton(false);
    } else {
      getBBPatient();
    }
  }, [isFromBlueButton, newSource]);

  const getBBPatient = async () => {
    const ssoAccessToken = await getAccessToken();
    const tokenPayload = JSON.parse(atob(ssoAccessToken.split(".")[1]));
    const userSub = tokenPayload.sub;
    const response = await dispatch(getBBPatientData(userSub));
    const bbActiveStatus = await response.payload?.bbIsActive;
    if (bbActiveStatus === "True") {
      setIsActiveCMS(true); // Ensure checkbox is checked
    }
  };

  const formatDateForQuery = (date) => {
    // Check if the date is an instance of Date and is not null
    if (date instanceof Date && !isNaN(date)) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    }
    return "";
  };

  const fetchClaims = async ({ fromDate, toDate }) => {
    setLoading(true);
    // Handle token for CMS source
    if (source === "CMS") {
      const storedToken =
        localStorage.getItem("BB_NewAccess_Token") ||
        sessionStorage.getItem("BB_NewAccess_Token") ||
        localStorage.getItem("local_B_AccessToken") ||
        sessionStorage.getItem("Session_B_AccessToken");
      if (storedToken) {
        setBlueButtonToken(storedToken);
      }
    }

    // Fetch patient data
    let patientIdObject = await fetchPatientData();
    let currentPatientId = null;
    let queryString = new URLSearchParams();
    // Add date filters to query string
    if (fromDate) queryString.append("service-date", `ge${fromDate}`);
    if (toDate) queryString.append("service-date", `le${toDate}`);

    // Determine currentPatientId based on source and patientIdObject
    if (source === "CMS" && patientIdObject.BB_MemberID) {
      currentPatientId = patientIdObject.BB_MemberID;
      if (!currentPatientId) {
        currentPatientId = localStorage.getItem("B_patientId");
      }
    } else if (source === "Healthplan") {
      currentPatientId =
        patientIdObject.MemberID ||
        (source === "Healthplan" && selectedPayerID ? selectedPayerID : null);
    } else {
      currentPatientId =
        selectedPatient ||
        payerListData?.find((payer) => payer.payerName === source)
          ?.payerMemberID;
    }

    queryString.append("patient", currentPatientId);
    queryString.append("_count", "10");
    queryString.append("status", "active");
    queryString.append("_sort", "-service-date");

    let headers = {};
    let requestInstance = blueButtonData; // Default to CMS instance

    const accessToken = await getAccessToken();
    headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    requestInstance = hl7Instance; // Use HL7 instance for all sources

    // Fetch claims
    try {
      const endpoint = "/ExplanationOfBenefit/";
      const response = await requestInstance.get(endpoint, {
        headers: headers,
        params: queryString,
      });
      const data = response.data;
      setClaims(data.entry || []);
      setTotalClaims(data.total || 0);

      // Handle pagination
      const computedPage = Math.floor(((data.entry?.length || 0) - 1) / 10) + 1;
      setCurrentPage(computedPage);
      const nextLink = data.link.find((l) => l.relation === "next");
      const baseURL = requestInstance.defaults.baseURL || "";
      const fullURL = `${baseURL}${endpoint}?${queryString}`;
      setUrlStack([fullURL]);
      if (nextLink) {
        setPaginationUrls({
          current: fullURL,
          next: nextLink.url,
          prev: "",
        });
        setPrevUrls(fullURL);
        setNextUrl(nextLink.url);
      }
    } catch (error) {
      console.error("Error fetching claims:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleClearSearch = () => {
    setTempDates({ fromDate: null, toDate: null });
    setSearchParams({ ...searchParams, fromDate: "", toDate: "" });
    fetchClaims({ fromDate: null, toDate: null });
  };
  useEffect(() => {
    if (!isSourceLoading && (source || newSource)) {
      fetchClaims({
        fromDate: searchParams.fromDate,
        toDate: searchParams.toDate,
      });
    }
  }, [isSourceLoading, source, blueButtonToken, newSource, payerListData]);

  useEffect(() => {
    if (page < prevPageRef.current) {
      setCurrentPage((prev) => prev - 1);
    }
  }, [claims]);

  useEffect(() => {
    const savedSource = localStorage.getItem("selectedSource");
    if (savedSource) {
      setSource(savedSource);
      setIsActiveCMS(savedSource === "CMS");
    }
    setIsSourceLoading(false);
  }, []);

  useEffect(() => {
    return () => {
      localStorage.removeItem("selectedSource");
    };
  }, []);

  useEffect(() => {
    prevPageRef.current = page;
  }, [page]);

  useEffect(() => {
    const selectedPayer = payerListData?.find(
      (payer) => payer.payerName === source
    );

    if (selectedPayer) {
      setSelectedPayerID(selectedPayer.payerMemberID);
      setSelectedPatient(selectedPayer.memberID);
      setPayerClaims([]);
    }
  }, []);

  useEffect(() => {
    if (isFromDetail) {
      const returnedSource = location.state?.source;
      setSource(returnedSource || savedSource);
      setIsActiveCMS(returnedSource === "CMS");
      localStorage.setItem("selectedSource", returnedSource); // Update localStorage
      setIsFromDetail(false);
    }
  }, [isFromDetail, location.state?.source]);

  const fetchNextPage = async () => {
    try {
      setError(null);
      setLoading(true);
      const accessToken = await getAccessToken();

      const response = await hl7Instance.get(paginationUrls.next, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const data = response.data;
      const prevLink = data.link.find((link) => link.relation === "previous");
      if (prevLink) {
        const prevUrl = prevLink.url;
        setNextFhirUrl(prevUrl); // Assuming setNextUrl is the state setter for the next URL
        setPaginationUrls((prev) => ({
          ...prev,
          prev: prevUrl,
        }));
      }
      if (source !== "CMS" && source !== "Healthplan") {
        setLoading(true);
        const claims =
          data.entry.map((item) => ({
            ...item,
            fullURL: item.fullUrl, // Assuming fullUrl is a property of item
          })) || [];

        setPayerClaims((prevClaims) => [...prevClaims, ...claims]);
        setLoading(false);
      }
      const nextLink = data.link.find((l) => l.relation === "next");
      setUrlStack((prevUrls) => [...prevUrls, paginationUrls.current]); // push the current URL
      setPaginationUrls({
        current: paginationUrls.next,
        next: nextLink ? nextLink.url : "",
        prev: urlStack[urlStack.length - 1] || "", // Set the previous URL
      });
      setClaims(data.entry || []);
      setCurrentPage((prevPage) => prevPage + 1);
      if (totalClaims === null) {
        setTotalClaims(data.total || 0);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Fetch Next Page Error:", error);
      setError(
        "An error occurred while fetching the next page. Please try again."
      );
    }
  };
  const fetchPreviousPage = async () => {
    try {
      setError(null);
      setLoading(true);

      if (urlStack.length <= 1) return;
      const prevUrl = urlStack[urlStack.length - 1];
      const accessToken = await getAccessToken();
      const response = await hl7Instance.get(prevUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = response.data;
      const nextLink = data.link.find((l) => l.relation === "next");

      setUrlStack((prevUrls) => {
        const newUrls = [...prevUrls];
        newUrls.pop();
        return newUrls;
      });

      setPaginationUrls({
        current: prevUrl,
        next: nextLink ? nextLink.url : "",
        prev: urlStack[urlStack.length - 2] || "",
      });

      setClaims(data.entry || []);
      setCurrentPage((prevPage) => prevPage - 1);
      if (totalClaims === null) {
        setTotalClaims(data.total || 0);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Fetch Previous Page Error:", error);
      setError(
        "An error occurred while fetching the previous page. Please try again."
      );
    }
  };
  const ITEMS_PER_PAGE = 10;
  const totalPages = Math.ceil(totalClaims / ITEMS_PER_PAGE);

  useEffect(() => {
    const token = localStorage.getItem("PortalAdmin-AccessToken");
    if (token) {
      try {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const jsonPayload = decodeURIComponent(
          atob(base64)
            .split("")
            .map(function (c) {
              return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
        );

        const payload = JSON.parse(jsonPayload);
        setUserSsoId(payload.sub); // Extract the 'sub' value from the payload
      } catch (error) { }
    }
  }, []);

  useEffect(() => {
    if (userSsoId) {
      fetchPayerList("", userSsoId, 1, 50);
    }
  }, [userSsoId]);

  useEffect(() => {
    if (source) {
      setIsSourceReady(true);
    }
  }, [source]);

  useEffect(() => {
    if (payerClaims.length === 0) {
      setTotalClaims(0);
    }
  }, [payerClaims]);

  const RenderHealthPlanContent = () => (
    <>
      <PerfectScrollbar className="table-responsive custom-main-table mb-2">
        <table className="table">
          <thead className="sticky-top z-1">
            <tr>
              <th>Claim&nbsp;Number</th>
              <th>Provider&nbsp;Name</th>
              <th>Date&nbsp;of&nbsp;Service</th>
              <th>Billed&nbsp;Amount</th>
              <th>Claim&nbsp;Status</th>
              <th>Claim&nbsp;Type</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={6} className="text-center my-4">
                  <div className="spinner-border" role="status"></div>
                </td>
              </tr>
            ) : claims && claims.length > 0 ? (
              claims.map((claim, index) => (
                <tr key={index + "_key"}>
                  <td>
                    <Link to={`/claim/${claim.resource.id}/${source}`}>
                      {claim.resource.identifier?.find(
                        (id) =>
                          id.system ===
                          "https://terminology.hl7.org/resources/variables/clm_id"
                      )?.value || claim.resource.id}
                    </Link>
                  </td>
                  <td>
                    {toTitleCase(claim.resource.provider?.display || "-")}
                  </td>
                  <td>
                    {claim.resource.billablePeriod?.start
                      ? formatDateToDDMMYY(claim.resource.billablePeriod?.start)
                      : "-"}
                  </td>
                  <td>
                    {formatCurrency(
                      claim.resource.extension?.find(
                        (ext) =>
                          ext.url ===
                          "https://terminology.hl7.org/resources/codesystem/eob-billed-amount"
                      )?.valueMoney?.value
                    ) || "-"}
                  </td>
                  <td>
                    {toTitleCase(
                      claim.resource.extension?.find(
                        (ext) =>
                          ext.url ===
                          "https://terminology.hl7.org/resources/codesystem/eob-status"
                      )?.valueCoding?.code ||
                      claim.resource?.status ||
                      "No Data Available" // Fallback for else condition
                    )}
                  </td>
                  <td>
                    {claim.resource.type?.text ||
                      (claim.resource.type?.coding &&
                        claim.resource.type.coding.length > 0
                        ? claim.resource.type.coding[0].code
                        : null) ||
                      "-"}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="text-center my-4">
                  <p>No Data Found</p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </PerfectScrollbar>
    </>
  );

  const RenderCMSContent = () => {
    const [providerNames, setProviderNames] = useState({});

    // Function to get provider name based on NPI
    const getProviderName = async (npi) => {
      // If NPI is valid (length is 10), fetch provider name
      if (npi && npi.length === 10) {
        try {
          const response = await BlueButtonApi.getProviderNameByNpi(npi);
          if (response) {
            return response;
          }
        } catch (error) {
          console.error("Error fetching provider name: ", error);
          return npi; // In case of error, return the NPI value itself
        }
      }
      // If NPI is invalid (not 10 digits), return the NPI value itself
      return npi;
    };

    // Fetch provider names when the claims change
    useEffect(() => {
      const fetchProviderNames = async () => {
        const newProviderNames = {};
        for (const claim of claims) {
          const npi = claim.resource.provider?.identifier?.value;
          if (npi) {
            const providerName = await getProviderName(npi);
            newProviderNames[npi] = providerName; // Store the provider name by NPI
          }
        }
        setProviderNames(newProviderNames); // Update the state with all fetched names
      };

      if (claims && claims.length > 0) {
        fetchProviderNames();
      }
    }, [claims]); // Re-run when claims change

    return (
      <>
        <PerfectScrollbar className="table-responsive custom-main-table mb-2">
          <table className="table">
            <thead className="sticky-top z-1">
              <tr>
                <th>Claim&nbsp;Number</th>
                <th>Provider&nbsp;Name</th>
                <th>Date&nbsp;of&nbsp;Service</th>
                <th>Billed&nbsp;Amount</th>
                <th>Claim&nbsp;Status</th>
                <th>Claim&nbsp;Type</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={6} className="text-center my-4">
                    <div className="spinner-border" role="status"></div>
                  </td>
                </tr>
              ) : claims && claims.length > 0 ? (
                claims.map((claim) => {
                  const npi = claim.resource.provider?.identifier?.value;
                  let providerName = toTitleCase(
                    claim.resource.contained?.[0]?.name ||
                    (npi && npi.length === 10 && providerNames[npi]) || // Use fetched provider name if NPI is valid
                    npi || // If NPI is invalid, render NPI itself
                    claim.resource?.careTeam[1]?.provider?.display ||
                    "-"
                  );

                  return (
                    <tr key={claim.resource.id}>
                      <td>
                        <Link to={`/claim/${claim.resource.id}/${source}`}>
                          {claim.resource.id}
                        </Link>
                      </td>
                      <td>{providerName}</td>
                      <td>
                        {formatDateToDDMMYY(
                          claim.resource.billablePeriod?.start
                        ) || "-"}
                      </td>
                      <td>
                        {formatCurrency(
                          claim.resource.payment?.amount?.value ||
                          (claim.resource?.total &&
                            claim.resource?.total[0]?.amount?.value)
                        ) || "-"}
                      </td>
                      <td>{toTitleCase(claim.resource.status || "-")}</td>
                      <td>
                        {claim.resource.type?.coding?.find(
                          (coding) =>
                            coding.system ===
                            "http://terminology.hl7.org/CodeSystem/claim-type"
                        )?.code || "-"}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={6} className="text-center my-4">
                    <p>No Data Found</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </PerfectScrollbar>
      </>
    );
  };

  const RenderPagination = () => {
    return (
      <>
        <span className="pagination-info me-2">Page</span>
        <ul className="pagination d-flex justify-content-end mb-0">
          {currentPage > 1 && (
            <li className="page-item">
              <button
                className="page-link"
                onClick={fetchPreviousPage}
                disabled={currentPage === 1}
              >
                <i className="fh_arrow_left_line"></i>
              </button>
            </li>
          )}
          <li className="page-item">
            <button className="page-link current-page">{currentPage}</button>
          </li>
          <li className="page-item">
            <button
              className="page-link me-0"
              onClick={fetchNextPage}
              disabled={currentPage >= totalPages}
            >
              <i className="fh_arrow_right_line"></i>
            </button>
          </li>
        </ul>
      </>
    );
  };

  const handleSourceChange = (e) => {
    const updatedSource = e?.target.value;

    // Store the selected source in localStorage
    localStorage.setItem("selectedSource", updatedSource);
    setIsSourceLoading(true);
    // Determine if the selected source is CMS, Healthplan, or Custom
    if (updatedSource === "CMS" || updatedSource === "Healthplan") {
      setSource(updatedSource); // Update source state for these values
      setNewSource(updatedSource); // Clear newSource for CMS or Healthplan
      setClaims([]);
    } else {
      setNewSource(updatedSource);
      setSource(updatedSource);
      setClaims([]);
    }

    // Find selected payer in payerListData and update states accordingly
    const selectedPayer = payerListData?.find(
      (payer) => payer.payerName === updatedSource
    );
    if (selectedPayer) {
      setSelectedPayerID(selectedPayer.payerMemberID);
      setSelectedPatient(selectedPayer.memberID);
      setPayerClaims([]);
    } else {
      setSelectedPayerID(null);
      setSelectedPatient(null);
      console.warn("Selected payer not found for source:", updatedSource);
    }
    setIsSourceLoading(false);
  };
  if (!isSourceReady) {
    return (
      <div className="text-center my-4">
        <div className="spinner-border" role="status"></div>
      </div>
    );
  }
  return (
    <>
      <div className="main-content">
        <div className="content-header">
          <h1 className="page-header">Claims</h1>
        </div>
        <div className="card">
          <div className="card-body">
            {error && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}
            <div className="card-form form-content mt-0">
              <div className="row">
                <div className="col-sm-6 col-md-4 col-xl-3 mb-4 mb-md-4 mb-lg-4 mb-xl-0">
                  <label htmlFor="source" className="d-flex form-label">
                    Source Plan
                  </label>

                  <div className="form-field position-relative">
                    <select
                      className="form-select form-control h-48 cursor-pointer"
                      id="source"
                      value={source ? source : newSource}
                      onChange={handleSourceChange}
                    >
                      <option value="Healthplan">Health Plan</option>
                      {isActiveCMS && <option value="CMS">CMS</option>}
                      {payerListData &&
                        payerListData
                          .filter((payer) => payer.isActive)
                          .map((payer, index) => (
                            <option key={index} value={payer?.payerName}>
                              {payer?.payerName}
                            </option>
                          ))}
                    </select>
                    <span className="field-icon position-absolute top-50 translate-middle-y">
                      <i className="fh_arrow_down_line"></i>
                    </span>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-xl-3 mb-4 mb-md-4 mb-lg-4 mb-xl-0">
                  <label htmlFor="fromDate" className="d-flex form-label">
                    From Date of Service
                  </label>
                  <div className="form-field">
                    <DatePicker
                      className={`form-control h-48`}
                      placeholderText="MM / DD / YYYY"
                      selected={tempDates.fromDate}
                      onChange={handleFromDateChange}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-xl-3 mb-4 mb-md-4 mb-lg-4 mb-xl-0">
                  <label htmlFor="toDate" className="d-flex form-label">
                    To Date of Service
                  </label>
                  <div className="form-field position-relative">
                    <DatePicker
                      id="toDate"
                      className={`form-control h-48`}
                      placeholderText="MM / DD / YYYY"
                      selected={tempDates.toDate}
                      onChange={handleToDateChange}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-12 col-xl-3 d-flex align-items-end align-items-sm-center align-items-md-end justify-content-start justify-content-center justify-content-md-center justify-content-xl-start justify-content-sm-start col-12 col-md-auto col-xs-12">
                  <button
                    type="button"
                    className="btn btn-primary h-48 me-3"
                    onClick={handleSearch}
                  >
                    <span>Search</span>
                  </button>
                  <button
                    type="button"
                    className="btn btn-border-primary h-48"
                    onClick={handleClearSearch}
                  >
                    <span>Clear</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="card-content">
              <div className="content-subheader d-flex align-items-center">
                <h2 className="page-subheader me-3">{source} Claim List</h2>
                <span className="total-record">
                  {/* [ Total Claims: {totalClaims ? totalClaims :!payerClaims ?0 :0} ] */}
                  [ Total Claims: {loading ? 0 : totalClaims} ]
                </span>
              </div>
              {loading ? (
                <>
                  <div className="text-center my-4">
                    <div className="spinner-border" role="status"></div>
                  </div>
                </>
              ) : (
                <>
                  {source === "CMS" ? (
                    <RenderCMSContent />
                  ) : (
                    <RenderHealthPlanContent />
                  )}

                  <div className="d-flex justify-content-end">
                    <nav
                      aria-label="Page navigation"
                      className="d-flex align-items-center"
                    >
                      <RenderPagination />
                    </nav>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Claim;
