import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fetchToken } from "../../config/blueButtonOAuthConfig";
import { UserManager } from "oidc-client";
import oidcConfig from "../../config/oidc-config";
import { useAuth } from "../../context/auth-context";
import CustomLoader from "../custom-loader/CustomLoader";
import Modal from "react-bootstrap/Modal";
import { getCurrentDateTime } from "../../utils/Utils";
import axios from "axios";
import {
  grantBBAccesss,
  updateBBPatient,
} from "../../redux/slice/BlueButtonSlice";
import { useDispatch } from "react-redux";
import { current } from "@reduxjs/toolkit";

const userManager = new UserManager(oidcConfig);

async function getAccessToken() {
  try {
    const user = await userManager.getUser();
    if (!user) {
      throw new Error("User is not authenticated");
    }
    return user.access_token;
  } catch (error) {
    throw error;
  }
}

const fetchPayerResourceBatch = async (requestBody) => {
  const apiUrl = process.env.REACT_APP_BB_RETRIVER;

  try {
    const response = await axios.post(apiUrl, requestBody, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
    
  } catch (error) {
    throw error;
  }
};

const BlueButtonCallback = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setBlueButtonToken } = useAuth();
  const [show, setShow] = useState(false);
  const [patientId, setPatientId] = useState("");

  useEffect(() => {
    const updateData = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");

      if (!code) {
        console.error("Authorization code not found!");
        return;
      }

      const userData = await fetchToken(code);
      
      if (userData && userData.access_token) {
        localStorage.setItem(
          "BB_Grant_Expiary",
          userData.access_grant_expiration
        );
        setBlueButtonToken(userData.access_token);
      }

      if (userData.patient) {
        const accessToken = await getAccessToken();
        setPatientId(userData.patient);
        const tokenPayload = JSON.parse(atob(accessToken.split(".")[1]));
        const userSub = tokenPayload.sub;

        const PayloadForGrantAccess = {
          SsoUserId: userSub,
          bbMemberID: userData.patient,
          bbIsActive: "Yes",
          bbRefreshToken: userData.refresh_token,
          accessGrantExpiration: userData.access_grant_expiration,
          isDataSync: false,
        };

        const res = await dispatch(grantBBAccesss(PayloadForGrantAccess));
        const requestBodyForfetchPayerResourceBatch = {
          PayerMemberID: userData.patient,
          SSOUserID: userSub,
          PayerID: res.payload.id,
        };

        const currentDateTime = getCurrentDateTime();

        try {
          await fetchPayerResourceBatch(requestBodyForfetchPayerResourceBatch);
          
          navigate("/claim", { state: { fromBlueButton: true } });
        } catch (error) {
          const updateBBData = {
            id: res.payload.id,
            isDataSync: true,
            dataSyncError: error.message,
            LastSyncDate: currentDateTime,
          };
          dispatch(updateBBPatient(updateBBData));

          navigate("/Aboutme");
        }
      }
    };

    updateData();
  }, [navigate]);

  return (
    <>
      <CustomLoader />
      <Modal
        show={show}
        onHide={() => navigate("/")}
        centered
        className="custom-pop-up about-me-popup"
        size="lg"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Redirection</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Request denied by the user. Please close the popup to move to the home
          page.
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-border-primary me-2"
            onClick={() => navigate("/")}
          >
            OK
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BlueButtonCallback;

 