import { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";
import { hl7Instance } from "../../api/axios";
const CareTeam = ({ selectedTabKey }) => {
  const [claims, setClaims] = useState([]);
  // console.log("claims from the care team is============>",claims)
  const [loading, setLoading] = useState(false);
  const [totalClaims, setTotalClaims] = useState(0);
  const [setNextUrl] = useState("");
  const [setPrevUrls] = useState([]);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationUrls, setPaginationUrls] = useState({
    current: "",
    next: "",
    prev: "",
  });
  const [searchParams, setSearchParams] = useState({
    date: "",
    codeText: "",
  });
  const initialUrl = "";
  const [urlStack, setUrlStack] = useState([initialUrl]);
  const memberID = useSelector(
    (state) => state?.clinicalInfo?.memberData?.memberID
  );
  const ITEMS_PER_PAGE = 10;
  const totalPages = Math.ceil(totalClaims / ITEMS_PER_PAGE);
  const token = localStorage.getItem("PortalAdmin-AccessToken");

  const fetchClaims = async ({ date = "", text = "" } = {}) => {
    setLoading(true);
    let queryString = new URLSearchParams();
    // Add required parameters to query string
    queryString.append("patient", memberID);
    queryString.append("_count", "10");

    //queryString.append("status", "active");
    //queryString.append("_sort", "-service-date");
    let headers = {};
    let requestInstance = hl7Instance; // Default to CMS instance
    const accessToken = token;
    headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    requestInstance = hl7Instance; // Use HL7 instance for all sources

    // Fetch claims
    try {
      const endpoint = "/CareTeam/";
      const response = await requestInstance.get(endpoint, {
        headers: headers,
        params: queryString,
      });
      const data = response.data;
      setClaims(data.entry || []);
      setTotalClaims(data.total || 0);

      // Handle pagination
      const computedPage = Math.floor(((data.entry?.length || 0) - 1) / 10) + 1;
      setCurrentPage(computedPage);
      const nextLink = data.link.find((l) => l.relation === "next");
      const baseURL = requestInstance.defaults.baseURL || "";
      const fullURL = `${baseURL}${endpoint}?${queryString}`;
      setUrlStack([fullURL]);
      if (nextLink) {
        setPaginationUrls({
          current: fullURL,
          next: nextLink.url,
          prev: "",
        });
        setPrevUrls(fullURL);
        setNextUrl(nextLink.url);
      }
    } catch (error) {
      console.error("Error fetching claims:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedTabKey === "careteam" && memberID) {
      fetchClaims();
    }
  }, [selectedTabKey, memberID]);

  const fetchNextPage = async () => {
    try {
      setError(null);
      setLoading(true);

      const response = await hl7Instance.get(paginationUrls.next, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = response.data;
      const prevLink = data.link.find((link) => link.relation === "previous");
      if (prevLink) {
        const prevUrl = prevLink.url;

        setPaginationUrls((prev) => ({
          ...prev,
          prev: prevUrl,
        }));
      }

      setLoading(true);
      const claims =
        data.entry.map((item) => ({
          ...item,
          fullURL: item.fullUrl, // Assuming fullUrl is a property of item
        })) || [];

      setClaims((prevClaims) => [...claims, ...claims]);
      setLoading(false);

      const nextLink = data.link.find((l) => l.relation === "next");
      setUrlStack((prevUrls) => [...prevUrls, paginationUrls.current]); // push the current URL
      setPaginationUrls({
        current: paginationUrls.next,
        next: nextLink ? nextLink.url : "",
        prev: urlStack[urlStack.length - 1] || "", // Set the previous URL
      });
      setClaims(data.entry || []);
      setCurrentPage((prevPage) => prevPage + 1);
      if (totalClaims === null) {
        setTotalClaims(data.total || 0);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Fetch Next Page Error:", error);
      setError(
        "An error occurred while fetching the next page. Please try again."
      );
    }
  };
  const fetchPreviousPage = async () => {
    try {
      setError(null);
      setLoading(true);

      if (urlStack.length <= 1) return;
      const prevUrl = urlStack[urlStack.length - 1];
      const response = await hl7Instance.get(prevUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = response.data;
      const nextLink = data.link.find((l) => l.relation === "next");

      setUrlStack((prevUrls) => {
        const newUrls = [...prevUrls];
        newUrls.pop();
        return newUrls;
      });

      setPaginationUrls({
        current: prevUrl,
        next: nextLink ? nextLink.url : "",
        prev: urlStack[urlStack.length - 2] || "",
      });

      setClaims(data.entry || []);
      setCurrentPage((prevPage) => prevPage - 1);
      if (totalClaims === null) {
        setTotalClaims(data.total || 0);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Fetch Previous Page Error:", error);
      setError(
        "An error occurred while fetching the previous page. Please try again."
      );
    }
  };
  return (
    <>
      <div className="card-form form-content mt-0">
        <div className="row">
          <div className="col-sm-6 col-md-6 col-xl-4 col-xxl-3 mb-4 mb-sm-0">
            <label htmlFor="providerType" className="d-flex form-label">
              Provider Type
            </label>

            <div className="form-field position-relative">
              <input
                id="providerType"
                type="text"
                className="form-control h-48"
                placeholder="Search for a provider"
              />
              <span className="field-icon position-absolute top-50 translate-middle-y">
                <i className="fh_search"></i>
              </span>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-xl-3 mb-4 mb-md-4 mb-lg-4 mb-xl-0">
            <label htmlFor="providerName" className="d-flex form-label">
              Provider Name
            </label>

            <div className="form-field position-relative">
              <input
                id="providerName"
                type="text"
                className="form-control h-48"
                placeholder="Search for a provider"
              />
              <span className="field-icon position-absolute top-50 translate-middle-y">
                <i className="fh_search"></i>
              </span>
            </div>
          </div>
          <div className="d-flex align-items-sm-end justify-content-center col-12 col-sm-auto">
            <button type="button" className="btn btn-primary h-48 me-3">
              <span>Search</span>
            </button>
            <button type="button" className="btn btn-border-primary h-48">
              <span>Clear</span>
            </button>
          </div>
        </div>
      </div>
      <div className="card-content">
        <div className="content-subheader d-flex align-items-center">
          <h2 className="page-subheader me-3">Care Team List</h2>
          <span className="total-record">
            [ Total Records : {totalClaims} ]
          </span>
        </div>
        <PerfectScrollbar className="table-responsive mb-2">
          <table className="table">
            <thead className="sticky-top z-1">
              <tr>
                <th>Provider NPI</th>
                <th>Provider Name</th>
                <th>Provider Type</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1427375518</td>
                <td>Perez</td>
                <td>PCP</td>
              </tr>
            </tbody>
          </table>
        </PerfectScrollbar>
        <div className="d-flex justify-content-end">
          <nav
            aria-label="Page navigation"
            className="d-flex align-items-center"
          >
            <>
              <span className="pagnination-info me-2">Page</span>
              <ul className="pagination d-flex mb-0">
                <li className="page-item">
                  <button
                    className="page-link"
                    disabled={currentPage === 1}
                    onClick={fetchPreviousPage}
                  >
                    <i className="fh_arrow_left_line"></i>
                  </button>
                </li>
                <li className="page-item">
                  <button className="page-link current-page">
                    {currentPage}
                  </button>
                </li>
                <li className="page-item">
                  <button
                    className="page-link me-0"
                    disabled={currentPage >= totalPages}
                    onClick={fetchNextPage}
                  >
                    <i className="fh_arrow_right_line"></i>
                  </button>
                </li>
              </ul>
            </>
          </nav>
        </div>
      </div>
    </>
  );
};

export default CareTeam;
