import PerfectScrollbar from "react-perfect-scrollbar";
import DatePicker from "react-datepicker";
const AllergyIntolerance = () => {
    return (
        <>
            <div className="card-form form-content mt-0">
                <div className="row">
                    <div className="col-sm-6 col-md-6 col-xl-4 col-xxl-3 mb-4 mb-sm-0">
                        <label htmlFor="allergyCode" className="d-flex form-label">
                            Allergy Code
                        </label>

                        <div className="form-field position-relative">
                            <input
                                id="allergyCode"
                                type="text"
                                className="form-control h-48"
                                placeholder="Search for a allergy"
                            />
                            <span className="field-icon position-absolute top-50 translate-middle-y">
                                <i className="fh_search"></i>
                            </span>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-xl-3 mb-4 mb-md-4 mb-lg-4 mb-xl-0">
                        <label htmlFor="onSetDate" className="d-flex form-label">
                            On Set Date
                        </label>
                        <div className="form-field">
                            <DatePicker
                                className={`form-control h-48`}
                                placeholderText="MM / DD / YYYY"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                            />
                        </div>
                    </div>
                    <div className="d-flex align-items-sm-end justify-content-center col-12 col-sm-auto">
                        <button
                            type="button"
                            className="btn btn-primary h-48 me-3"
                        >
                            <span>Search</span>
                        </button>
                        <button
                            type="button"
                            className="btn btn-border-primary h-48"
                        >
                            <span>Clear</span>
                        </button>
                    </div>
                </div>
            </div>
            <div className="card-content">
                <div className="content-subheader d-flex align-items-center">
                    <h2 className="page-subheader me-3">Allergy Intolerance List</h2>
                    <span className="total-record">
                        [ Total Records : 1 ]
                    </span>
                </div>
                <PerfectScrollbar className="table-responsive mb-2">
                    <table className="table">
                        <thead className="sticky-top z-1">
                            <tr>
                                <th>Allergy Code</th>
                                <th>Clinical Status</th>
                                <th>Provider Name</th>
                                <th>Verification Status</th>
                                <th>On Set Date Time</th>
                                <th>Allergy Description</th>
                                <th>Reaction</th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr>
                                <td>5933</td>
                                <td>Active</td>
                                <td>DR. GREG FITZKE</td>
                                <td>unconfirmed</td>
                                <td>18-01-2021</td>
                                <td>IODINE</td>
                                <td>Itching</td>
                            </tr>
                        </tbody>
                    </table>
                </PerfectScrollbar>
                <div className="d-flex justify-content-end">
                    <nav
                        aria-label="Page navigation"
                        className="d-flex align-items-center"
                    >
                        <>
                            <span className="pagnination-info me-2">Page</span>
                            <ul className="pagination d-flex mb-0">
                                <li className="page-item">
                                    <button
                                        className="page-link"

                                    >
                                        <i className="fh_arrow_left_line"></i>
                                    </button>
                                </li>
                                <li className="page-item">
                                    <button className="page-link current-page">
                                        1
                                    </button>
                                </li>
                                <li className="page-item">
                                    <button
                                        className="page-link me-0"
                                    >
                                        <i className="fh_arrow_right_line"></i>
                                    </button>
                                </li>
                            </ul>
                        </>
                    </nav>
                </div>
            </div>
        </>
    );
};

export default AllergyIntolerance;