import "./Authorization.scss";
const AuthCodes = () => {
    const codesDetails = [
        { icon: 'fh_bar_chart', label: 'Level Of Service Code', value: 'Urgent' },
        { icon: 'fh_mail_post', label: 'POS Code', value: '12' },
        { icon: 'fh_info_line', label: 'Priority', value: 'Normal' },
    ];
    return (
        <>
            <div className="card mb-4 w-full">
                <div className="card-body pb-0">
                    <div className="card-content">
                        <div className="content-subheader">
                            <h2 className="page-subheader">Codes & Priority</h2>
                        </div>
                        <div className="row">
                            {codesDetails.map((item, index) => (
                                <div
                                    key={index}
                                    className="mb-3"
                                >
                                    <div className="label-info-main d-flex align-items-center">
                                        <i className={item.icon}></i>
                                        <div>
                                            <label>{item.label}</label>
                                            <span>{item.value}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AuthCodes;
