import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import { hl7Instance } from "../../api/axios";
import { toTitleCase, formatDateToDDMMYY } from "../../utils/Utils";

const MedicationRequest = ({ selectedTabKey }) => {
  const [allMedications, setAllMedications] = useState([]); // Store all records
  const [filteredMedications, setFilteredMedications] = useState([]); // Store paginated records
  const [loading, setLoading] = useState(false);
  const [ndcSearchText, setNdcSearchText] = useState("");
  const [providerSearchText, setProviderSearchText] = useState("");
  const [filteredCount, setFilteredCount] = useState(null); // Track filtered count
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 10;

  const token = localStorage.getItem("PortalAdmin-AccessToken");
  const memberID = useSelector(
    (state) => state?.clinicalInfo?.memberData?.memberID
  );

  // Fetch all medication requests
  const fetchAllMedications = async () => {
    setLoading(true);
    try {
      let allEntries = [];
      let nextPage = `/MedicationRequest?patient=${memberID}&_count=50`; // Fetch in batches
      while (nextPage) {
        const response = await hl7Instance.get(nextPage, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const data = response.data;
        allEntries = [...allEntries, ...(data.entry || [])];

        // Get next page if available
        const nextLink = data.link?.find((l) => l.relation === "next");
        nextPage = nextLink ? nextLink.url : "";
      }
      setAllMedications(allEntries);
      setFilteredMedications(allEntries.slice(0, ITEMS_PER_PAGE)); // Display first page
    } catch (error) {
      console.error("Error fetching medications:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedTabKey === "medicationRequest" && memberID) {
      setCurrentPage(1);
      fetchAllMedications();
    }
  }, [selectedTabKey, memberID]);

  // Handle search across all records
  const handleSearch = () => {
    setLoading(true);
    setTimeout(() => {
      let filtered = allMedications;

      if (ndcSearchText.trim()) {
        filtered = filtered.filter((entry) =>
          (
            entry.resource?.medicationCodeableConcept?.coding?.[0]?.display ||
            ""
          )
            .toLowerCase()
            .includes(ndcSearchText.trim().toLowerCase())
        );
      }
      if (providerSearchText.trim()) {
        filtered = filtered.filter((entry) =>
          (entry.resource?.requester?.display || "")
            .toLowerCase()
            .includes(providerSearchText.trim().toLowerCase())
        );
      }

      setFilteredMedications(filtered.slice(0, ITEMS_PER_PAGE)); // Paginate search results
      setFilteredCount(filtered.length);
      setCurrentPage(1);
      setLoading(false);
    }, 500);
  };

  const handleClear = () => {
    setLoading(true); // Show loading spinner
    setTimeout(() => {
      setNdcSearchText("");
      setProviderSearchText("");
      setFilteredCount(null);
      setFilteredMedications(allMedications.slice(0, ITEMS_PER_PAGE));
      setCurrentPage(1);
      setLoading(false); // Hide loading spinner
    }, 500);
  };

  const fetchNextPage = () => {
    setLoading(true); // Show loading spinner
    setTimeout(() => {
      const totalRecords =
        filteredCount !== null ? filteredCount : allMedications.length;
      const startIndex = currentPage * ITEMS_PER_PAGE;
      const newPageData = (
        filteredCount !== null
          ? allMedications.slice(0, filteredCount)
          : allMedications
      ).slice(startIndex, startIndex + ITEMS_PER_PAGE);

      if (newPageData.length > 0) {
        setFilteredMedications(newPageData);
        setCurrentPage((prevPage) => prevPage + 1);
      }
      setLoading(false); // Hide loading spinner
    }, 500);
  };

  const fetchPreviousPage = () => {
    if (currentPage > 1) {
      setLoading(true); // Show loading spinner
      setTimeout(() => {
        const startIndex = (currentPage - 2) * ITEMS_PER_PAGE;
        setFilteredMedications(
          (filteredCount !== null
            ? allMedications.slice(0, filteredCount)
            : allMedications
          ).slice(startIndex, startIndex + ITEMS_PER_PAGE)
        );
        setCurrentPage((prevPage) => prevPage - 1);
        setLoading(false); // Hide loading spinner
      }, 500);
    }
  };

  return (
    <>
      <div className="card-form form-content mt-0">
        <div className="row">
          <div className="col-sm-6 col-md-6 col-xl-4 col-xxl-3 mb-4 mb-sm-0">
            <label htmlFor="NDCDescription" className="d-flex form-label">
              NDC Description
            </label>
            <div className="form-field position-relative">
              <input
                id="NDCDescription"
                type="text"
                className="form-control h-48"
                placeholder="Search for NDC Description"
                value={ndcSearchText}
                onChange={(e) => setNdcSearchText(e.target.value)}
              />
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-xl-3 mb-4 mb-md-4 mb-lg-4 mb-xl-0">
            <label htmlFor="providerName" className="d-flex form-label">
              Provider Name
            </label>
            <div className="form-field position-relative">
              <input
                id="providerName"
                type="text"
                className="form-control h-48"
                placeholder="Search for Provider Name"
                value={providerSearchText}
                onChange={(e) => setProviderSearchText(e.target.value)}
              />
            </div>
          </div>
          <div className="d-flex align-items-sm-end justify-content-center col-12 col-sm-auto">
            <button
              type="button"
              className="btn btn-primary h-48 me-3"
              onClick={handleSearch}
            >
              <span>Search</span>
            </button>
            <button
              type="button"
              className="btn btn-border-primary h-48"
              onClick={handleClear}
            >
              <span>Clear</span>
            </button>
          </div>
        </div>
      </div>

      <div className="card-content">
        <div className="content-subheader d-flex align-items-center">
          <h2 className="page-subheader me-3">Medication Requests</h2>
          <span className="total-record">
            [ Total Records:{" "}
            {filteredCount !== null ? filteredCount : allMedications.length} ]
          </span>
        </div>

        <PerfectScrollbar className="table-responsive mb-2">
          <table className="table">
            <thead className="sticky-top">
              <tr>
                <th>Medication Request Id</th>
                <th>Status</th>
                <th>Intent</th>
                <th>NDC Code</th>
                <th>NDC Description</th>
                <th>Authored On</th>
                <th>Provider Name</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={7} className="text-center my-4">
                    <div className="spinner-border" role="status"></div>
                  </td>
                </tr>
              ) : filteredMedications.length > 0 ? (
                filteredMedications.map((entry, index) => (
                  <tr key={index}>
                    <td>{entry.resource.id}</td>
                    <td>{toTitleCase(entry.resource.status)}</td>
                    <td>{toTitleCase(entry.resource.intent)}</td>
                    <td>
                      {entry.resource.medicationCodeableConcept?.coding?.[0]
                        ?.code || "N/A"}
                    </td>
                    <td>
                      {entry.resource.medicationCodeableConcept?.coding?.[0]
                        ?.display || "N/A"}
                    </td>
                    <td>
                      {formatDateToDDMMYY(entry.resource.authoredOn) || "N/A"}
                    </td>
                    <td>{entry.resource.requester?.display || "N/A"}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="text-center">
                    No medication requests available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </PerfectScrollbar>

        <div className="d-flex justify-content-end">
          <nav
            aria-label="Page navigation"
            className="d-flex align-items-center"
          >
            <span className="pagination-info me-2">Page</span>
            <ul className="pagination d-flex mb-0">
              <li className="page-item">
                <button
                  className="page-link"
                  disabled={currentPage === 1}
                  onClick={fetchPreviousPage}
                >
                  <i className="fh_arrow_left_line"></i>
                </button>
              </li>
              <li className="page-item">
                <button className="page-link current-page">
                  {currentPage}
                </button>
              </li>
              <li className="page-item">
                <button
                  className="page-link me-0"
                  disabled={
                    currentPage * ITEMS_PER_PAGE >=
                    (filteredCount !== null
                      ? filteredCount
                      : allMedications.length)
                  }
                  onClick={fetchNextPage}
                >
                  <i className="fh_arrow_right_line"></i>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default MedicationRequest;
