import { urls } from "../Urls";
import { Http } from "../Http";

export class BlueButtonApi {
  static getBlueButtonConfig() {
    return Http.get(urls.blueButton.getBluButtonConfigurations);
  }
  static GetBlueButtonData(ssoguid: string) {
    const url = urls.blueButton.GetBlueButtonPatient(ssoguid);
    return Http.get(url);
  }

  static getProviderNameByNpi(npiNumber:number){
    const url = urls.blueButton.getProviderName(npiNumber)
    return Http.get(url)
  }
  static grantBlueButtonAccess(data: object) {
    return Http.post(urls.blueButton.grantBlueButtonAccess, data);
  }
  static revolkeBlueButtonAccess(data: object) {
    return Http.put(urls.blueButton.revokeBlueButtonAccess, data);
  }

  static updateBlueButtonPatient(data: object) {
    return Http.put(urls.blueButton.updateBlueButtonPatient, data);
  }
}
