import { configureStore } from '@reduxjs/toolkit';
import previousInsuranceReducer from './slice/PrevInsuranceSlice';
import blueButtonReducer from "./slice/BlueButtonSlice";
import clinicalInfoReducer from "./slice/ClinicalInforSlice";
export const store = configureStore({
  reducer: {
    insuranceData: previousInsuranceReducer,
    blueButtonData:blueButtonReducer,
    clinicalInfo:clinicalInfoReducer
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
