import "./Authorization.scss";
const AuthDates = () => {
    const dateDetails = [
        { icon: 'fh_today_fill', label: 'Service Start Date', value: '10/1/2021' },
        { icon: 'fh_event_fill', label: 'Service End Date', value: '10/31/2021' },
        { icon: 'fh_event_available_fill', label: 'Created Date', value: '10/1/2021' },

    ];

    return (
        <>
            <div className="card mb-4 w-full">
                <div className="card-body pb-0">
                    <div className="card-content">
                        <div className="content-subheader">
                            <h2 className="page-subheader">Dates</h2>
                        </div>
                        <div className="row">
                            {dateDetails.map((item, index) => (
                                <div
                                    key={index}
                                    className="mb-3"
                                >
                                    <div className="label-info-main d-flex align-items-center">
                                        <i className={item.icon}></i>
                                        <div>
                                            <label>{item.label}</label>
                                            <span>{item.value}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default AuthDates;
