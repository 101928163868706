
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link, useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";

const AuthorizationList = () => {
    return (
        <>
            <div className="main-content">
                <div className="content-header">
                    <h1 className="page-header">Prior Authorization</h1>
                </div>
                <div className="card">
                    <div className="card-body custom-tab">
                        <div className="card-form form-content mt-0">
                            <div className="row">
                                <div className="col-sm-6 col-md-4 col-xl-3 mb-4 mb-md-4 mb-lg-4 mb-xl-0">
                                    <label htmlFor="provider" className="d-flex form-label">
                                        Provider Name
                                    </label>

                                    <div className="form-field position-relative">
                                        <input
                                            id="provider"
                                            type="text"
                                            className="form-control h-48"
                                            placeholder="Search for a provider"
                                        />
                                        <span className="field-icon position-absolute top-50 translate-middle-y">
                                            <i className="fh_search"></i>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-xl-3 mb-4 mb-md-4 mb-lg-4 mb-xl-0">
                                    <label htmlFor="providerName" className="d-flex form-label">
                                        Outcome
                                    </label>

                                    <div className="form-field position-relative">
                                        <select
                                            className="form-select form-control h-48 cursor-pointer"
                                            id="source"
                                        >
                                            <option value="Healthplan">Complete</option>
                                            <option value="Healthplan">Error</option>
                                        </select>
                                        <span className="field-icon position-absolute top-50 translate-middle-y">
                                            <i className="fh_arrow_down_line"></i>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-xl-3 mb-4 mb-md-4 mb-lg-4 mb-xl-0">
                                    <label htmlFor="toDate" className="d-flex form-label">
                                        Service Start Date
                                    </label>
                                    <div className="form-field position-relative">
                                        <DatePicker
                                            id="toDate"
                                            className={`form-control h-48`}
                                            placeholderText="MM / DD / YYYY"
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-12 col-xl-3 d-flex align-items-end align-items-sm-center align-items-md-end justify-content-start justify-content-center justify-content-md-center justify-content-xl-start justify-content-sm-start col-12 col-md-auto col-xs-12">
                                    <button
                                        type="button"
                                        className="btn btn-primary h-48 me-3"
                                    >
                                        <span>Search</span>
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-border-primary h-48"
                                    >
                                        <span>Clear</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="card-content">
                            <div className="content-subheader d-flex align-items-center">
                                <h2 className="page-subheader me-3">Prior Authorization List</h2>
                                <span className="total-record">
                                    [ Total Records : 1 ]
                                </span>
                            </div>
                            <PerfectScrollbar className="table-responsive mb-2">
                                <table className="table">
                                    <thead className="sticky-top z-1">
                                        <tr>
                                            <th>Authorization&nbsp;ID</th>
                                            <th className="m-w-140">Provider</th>
                                            <th>Type</th>
                                            <th>Service&nbsp;Start&nbsp;Date</th>
                                            <th>Outcome</th>
                                            <th>Priority</th>
                                            <th>Adjudication&nbsp;Reason</th>
                                            <th>Eligible&nbsp;Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <Link to={`/Authorization/Authorizationview`}>
                                                    PA123456
                                                </Link>
                                            </td>
                                            <td>Provider A</td>
                                            <td>Institutional</td>
                                            <td>10/1/2021</td>
                                            <td>Complete</td>
                                            <td>Normal</td>
                                            <td>-</td>
                                            <td className="fw-bold">$300</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </PerfectScrollbar>
                            <div className="d-flex justify-content-end">
                                <nav
                                    aria-label="Page navigation"
                                    className="d-flex align-items-center"
                                >
                                    <>
                                        <span className="pagnination-info me-2">Page</span>
                                        <ul className="pagination d-flex mb-0">
                                            <li className="page-item">
                                                <button
                                                    className="page-link"

                                                >
                                                    <i className="fh_arrow_left_line"></i>
                                                </button>
                                            </li>
                                            <li className="page-item">
                                                <button className="page-link current-page">
                                                    1
                                                </button>
                                            </li>
                                            <li className="page-item">
                                                <button
                                                    className="page-link me-0"
                                                >
                                                    <i className="fh_arrow_right_line"></i>
                                                </button>
                                            </li>
                                        </ul>
                                    </>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AuthorizationList;