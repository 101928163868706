import {
  createSlice,
  createAsyncThunk,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import { PreviousInsuranceApi } from "../../api/services/PrevInsuranceService";
import { ClicialInfoAPI } from "../../api/services/ClinicalInfoApi";

const initialState = {
  isLoading: false,
  error: null as string | null,
  memberData: null,
  proceduralClaims:null as string | null,
  conditionClaims: null as string | null,
  CarePlanClaims:null as string | null,
  MedicationRequests:null as string | null,
};

export const getPatientDetails = createAsyncThunk(
  "patient/getPatientDetails",
  async (ssoGuid) => {
    try {
      const response = await PreviousInsuranceApi.getBBMemberDetails(ssoGuid);
      return response;
    } catch (error) {
      return error;
    }
  }
);
export const getProceduralClaims = createAsyncThunk(
    "patient/getProceduralClaims",
    async ({id,count}:any) => {
      try {
        const response = await ClicialInfoAPI.getProceduralCalim(id,count);
        return response;
      } catch (error) {
        return [];
      }
    }
  ); 

  export const getProblemClaims = createAsyncThunk(
    "patient/getProblemClaims",
    async ({id,count}:any) => {
      try {
        const response = await ClicialInfoAPI.getProblemsClaims(id,count);
        return response;
      } catch (error) {
        return [];
      }
    }
  );  

  export const getCareClaims = createAsyncThunk(
    "patient/getCareClaims",
    async ({id,category}:any) => {
      try {
        const response = await ClicialInfoAPI.getcarePlanClaim(id,category);
        return response;
      } catch (error) {
        return [];
      }
    }
  );

    export const getMedications = createAsyncThunk(
      "patient/getMedications",
      async ({id,codeText}:any) => {
        try {
          const response = await ClicialInfoAPI.getMedicationRequest(id,codeText);
          return response;
        } catch (error) {
          return [];
        }
      }
  );

const ClinicalInfoSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPatientDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.memberData = action.payload;
      })
      .addCase(getProceduralClaims.fulfilled, (state, action) => {
        state.isLoading = false;
        state.proceduralClaims = action.payload;
      })
      .addCase(getProblemClaims.fulfilled, (state, action) => {
        state.isLoading = false;
        state.conditionClaims = action.payload;
      })
      .addCase(getCareClaims.fulfilled, (state, action) => {
        state.isLoading = false;
        state.CarePlanClaims = action.payload;
      })
      .addCase(getMedications.fulfilled, (state, action) => {
        state.isLoading = false;
        state.MedicationRequests = action.payload;
      })
      
      .addMatcher(isPending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addMatcher(isRejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? null;
      });
  },
});

export default ClinicalInfoSlice.reducer;
