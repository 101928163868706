const AuthProvider = () => {
    const providerDetails = [
        { icon: 'fh_profile', label: 'Provider', value: 'Provider A' },
        { icon: 'fh_doctor', label: 'Provider NPI', value: '1861817728' },
        { icon: 'fh_provider_alert', label: 'Requesting Provider', value: 'Dr. Jhon' },
        { icon: 'fh_task_2_fill', label: 'Requesting Provider NPI', value: '1093001745' },
    ];
    return (
        <div className="card mb-4 w-full">
            <div className="card-body pb-0">
                <div className="card-content">
                    <div className="content-subheader">
                        <h2 className="page-subheader">Provider Details</h2>
                    </div>
                    <div className="row">
                        {providerDetails.map((item, index) => (
                            <div
                                key={index}
                                className="mb-3"
                            >
                                <div className="label-info-main d-flex align-items-center">
                                    <i className={item.icon}></i>
                                    <div>
                                        <label>{item.label}</label>
                                        <span>{item.value}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AuthProvider;
