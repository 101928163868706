import { useNavigate } from "react-router-dom";
import AuthService from "./AuthService";
import AuthDiagnosis from "./AuthDiagnosis";
import AuthProvider from "./AuthProvider";
import AuthInfo from "./AuthInfo";
import AuthDates from "./AuthDates";
import AuthOutcome from "./AuthOutcome";
import AuthCodes from "./AuthCodes";
import "./Authorization.scss";

const AuthorizationDetail = () => {
    const navigate = useNavigate();
    return (
        <div className="main-content">
            <div className="content-header">
                <button
                    type="button"
                    className="btn btn-border-primary h-48 d-flex align-items-center"
                    onClick={() => navigate("/Authorization")}
                >
                    <i className="fh_arrow_left icon-mr"></i>
                    <span>Back to Authorization list</span>
                </button>
            </div>
            <div className="row">
                <div className="col-md-6 col-xl-4 col-xxl-4 col-2xl d-flex align-items-stretch">
                    <AuthInfo />
                </div>
                <div className="col-md-6 col-xl-4 col-xxl-4 col-2xl d-flex align-items-stretch">
                    <AuthDates />
                </div>
                <div className="col-md-6 col-xl-4 col-xxl-4 col-2xl d-flex align-items-stretch">
                    <AuthOutcome />
                </div>
                <div className="col-md-6 col-xl-4 col-xxl-4 col-2xl d-flex align-items-stretch">
                    <AuthCodes />
                </div>
                <div className="col-md-6 col-xl-4 col-xxl-4 col-2xl d-flex align-items-stretch">
                    <AuthProvider />
                </div>
            </div>
            <div className="row">
                <div className="diagnosis d-flex align-items-stretch">
                    <AuthDiagnosis />
                </div>
                <div className="service d-flex align-items-stretch">
                    <AuthService />
                </div>
            </div>
        </div>
    );
};

export default AuthorizationDetail;
