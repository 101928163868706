const AuthDiagnosis = () => {
    const diagnosisCodes = [
        { code: 'G89.4', description: 'Chronic pain syndrome' },
        { code: 'J45.90', description: 'Unspecified asthma' },
    ];

    return (
        <div className="card mb-4 w-full">
            <div className="card-body">
                <div className="card-content">
                    <div className="content-subheader">
                        <h2 className="page-subheader">Diagnosis Codes</h2>
                    </div>
                    <div className="table-responsive">
                        <table className="table mb-0">
                            <thead>
                                <tr>
                                    <th>Code</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                {diagnosisCodes.map((item, index) => (
                                    <tr key={index}>
                                        <td className="fw-bold">{item.code}</td>
                                        <td>{item.description}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AuthDiagnosis;