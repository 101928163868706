import "./leftBar.scss";
import React, { useState } from "react";
import { useNavigate, useLocation, matchRoutes } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import "./leftBar.scss";
import SidebarToggle from "../navbar/SidebarToggle";
import freedomLogo from "../../assets/img/logo/freedom/logo.svg";
import healthSunLogo from "../../assets/img/logo/healthsun/logo.svg";
import optimumLogo from "../../assets/img/logo/Optimum/logo.svg";
import demoLogo from "../../assets/img/logo/demo/demo-logo.svg";
import healthSunLogoIcon from "../../assets/img/healthsun-icon.svg";
import freedomLogoIcon from "../../assets/img/freedom-icon.svg";
import optimumLogoIcon from "../../assets/img/optimum-icon.svg";
import securLogo from "../../assets/img/secure-logo.svg"
import secureIcon from "../../assets/img/secure-icon.svg"
import ultimateLogo from "../../assets/img/ultimate-logo.svg"
import ultimateIcon from "../../assets/img/ultimate-icon.svg"
import demoLogoIcon from "../../assets/img/logo/demo/demo-icon.svg"
import { Tooltip } from "react-tooltip";

const LeftBar = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);

  const handleNavigation = (event, to) => {
    event.preventDefault();
    if (!user) {
      navigate("/post-logout");
    } else {
      navigate(to);
    }
  };
  const handleToggle = () => {
    setToggle(!toggle);
  };
  const useCurrentPath = (to) => {
    const location = useLocation().pathname;

    return location.toLowerCase() === to.toLowerCase();
  };
  const isAboutMePath = useCurrentPath("/Aboutme");
  const isRootPath = useCurrentPath("/");
  const clientName = process.env.REACT_APP_ClientName;

  const getLogoSource = () => {
    switch (clientName) {
      case "freedom":
        return `${freedomLogo}`;
      case "healthsun":
        return `${healthSunLogo}`;
      case "optimum":
        return `${optimumLogo}`;
      case "demo":
        return `${demoLogo}`;
      case "secur":
        return `${securLogo}`;
      case "ultimate":
        return `${ultimateLogo}`;
      default:
        return "";
    }
  };

  const getLogoIcon = () => {
    switch (clientName) {
      case "freedom":
        return `${freedomLogoIcon}`;
      case "healthsun":
        return `${healthSunLogoIcon}`;
      case "optimum":
        return `${optimumLogoIcon}`;
      case "demo":
        return `${demoLogoIcon}`;
      case "secur":
        return `${secureIcon}`;
      case "ultimate":
        return `${ultimateIcon}`
      default:
        return "";
    }
  };

  return (
    <>
      <nav className="sb-sidenav accordion" id="sidenavAccordion">
        <div className="header-section d-flex justify-content-between align-items-center">
          <div className="client-logo ">
            <img
              src={getLogoSource()}
              alt="Client Logo"
              href="/Aboutme"
              onClick={(e) => handleNavigation(e, "/Aboutme")}
            />
          </div>
          <SidebarToggle toggleSideBar={handleToggle} />{" "}
          <img
            src={getLogoIcon()}
            alt="Client Logo"
            className="client-icon w40"
            href="/Aboutme"
            onClick={(e) => handleNavigation(e, "/Aboutme")}
          />
        </div>
        {toggle && <Tooltip anchorSelect=".custom-tootip" />}
        <div className="sb-sidenav-menu">
          <div className="nav">
            <a
              className={
                "custom-tootip nav-link " +
                (isAboutMePath || isRootPath ? "active" : "")
              }
              href="/Aboutme"
              onClick={(e) => handleNavigation(e, "/Aboutme")}
              data-tooltip-id="Aboutme"
              data-tooltip-content="About me"
              data-tooltip-place="right"
            >
              <div className="sb-nav-link-icon">
                <i className="fh_user_fill"></i>
              </div>
              <span>About me</span>
            </a>
            <a
              className={
                "custom-tootip nav-link " +
                (useCurrentPath("/Claim") ? "active" : "")
              }
              href="/Claim"
              onClick={(e) => handleNavigation(e, "/Claim")}
              data-tooltip-id="Claims"
              data-tooltip-content="Claims"
              data-tooltip-place="right"
            >
              <div className="sb-nav-link-icon">
                <i className="fh_summary"></i>
              </div>
              <span>Claims</span>
            </a>
            <a
              className={
                "custom-tootip nav-link " +
                (useCurrentPath("/Provider") ? "active" : "")
              }
              href="/Provider"
              onClick={(e) => handleNavigation(e, "/Provider")}
              data-tooltip-content="Provider Directory"
              data-tooltip-place="right"
            >
              <div className="sb-nav-link-icon">
                <i className="fh_dr_specialist"></i>
              </div>
              <span>Provider Directory</span>
            </a>
            <a
              className={
                "custom-tootip nav-link " +
                (useCurrentPath("/Formulary") ? "active" : "")
              }
              href="/Formulary"
              onClick={(e) => handleNavigation(e, "/Formulary")}
              data-tooltip-content="Formulary Directory"
              data-tooltip-place="right"
            >
              <div className="sb-nav-link-icon">
                <i className="fh_bottle_med_2"></i>
              </div>
              <span>Formulary Directory</span>
            </a>
            <a
              className={
                "custom-tootip nav-link " +
                (useCurrentPath("/clinical-information") ? "active" : "")
              }
              href="/clinical-information"
              onClick={(e) => handleNavigation(e, "/clinical-information")}
              data-tooltip-content="Clinical Information"
              data-tooltip-place="right"
            >
              <div className="sb-nav-link-icon">
                <i className="fh_local_hospital_fill"></i>
              </div>
              <span>Clinical Information</span>
            </a>
            <a
              className={
                "custom-tootip nav-link " +
                (useCurrentPath("/Authorization") ? "active" : "")
              }
              href="/Authorization"
              onClick={(e) => handleNavigation(e, "/Authorization")}
              data-tooltip-content="Authorization"
              data-tooltip-place="right"
            >
              <div className="sb-nav-link-icon">
                <i className="fh_secure_sheild"></i>
              </div>
              <span>Prior Authorization</span>
            </a>
            <a
              className={
                "custom-tootip nav-link " +
                (useCurrentPath("/Lab") ? "active" : "")
              }
              href="/Lab"
              onClick={(e) => handleNavigation(e, "/Lab")}
              data-tooltip-content="Lab Result"
              data-tooltip-place="right"
            >
              <div className="sb-nav-link-icon">
                <i className="fh_lab"></i>
              </div>
              <span>Lab Result</span>
            </a>
            <a
              className={
                "custom-tootip nav-link " +
                (useCurrentPath("/PreviousInsurer") ? "active" : "")
              }
              href="/Lab"
              onClick={(e) => handleNavigation(e, "/PreviousInsurer")}
              data-tooltip-content="Previous Insurer"
              data-tooltip-place="right"
            >
              <div className="sb-nav-link-icon">
                <i className="fh_dollar_circle_fill"></i>
              </div>
              <span>Previous Insurer</span>
            </a>
          </div>
        </div>
        <div className="sb-sidenav-footer">
          <div className="footer-info">
            <div className="small-txt">Powered By</div>
            <img
              src={require("../../assets/img/aaneel-poweredby.svg").default}
              alt="mySvgImage"
            />
          </div>
        </div>
      </nav>
    </>
  );
};

export default LeftBar;