import PerfectScrollbar from "react-perfect-scrollbar";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { hl7Instance } from "../../api/axios";
import { toTitleCase, formatDateToDDMMYY } from "../../utils/Utils";
import { BlueButtonApi } from "../../api/services/BlueButtonService";

const ProcedureComponent = ({ selectedTabKey }) => {
  const [claims, setClaims] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalClaims, setTotalClaims] = useState(0);
  const [setNextUrl] = useState("");
  const [setPrevUrls] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationUrls, setPaginationUrls] = useState({
    current: "",
    next: "",
    prev: "",
  });
  const [searchParams, setSearchParams] = useState({
    date: "",
    codeText: "",
  });
  const [providerNames, setProviderNames] = useState({});
  const initialUrl = "";
  const [urlStack, setUrlStack] = useState([initialUrl]);
  const memberID = useSelector(
    (state) => state?.clinicalInfo?.memberData?.memberID
  );
  const ITEMS_PER_PAGE = 10;
  const totalPages = Math.ceil(totalClaims / ITEMS_PER_PAGE);
  const token = localStorage.getItem("PortalAdmin-AccessToken");

  const fetchClaims = async ({ date = "", text = "" } = {}) => {
    if (selectedTabKey !== "procedure") return;
    setLoading(true);
    let queryString = new URLSearchParams();
    // Add required parameters to query string
    queryString.append("patient", memberID);
    queryString.append("_count", "10");
    if (date) queryString.append("date", `${date}`);
    if (text) queryString.append("code:text", `${text}`);
    //queryString.append("status", "active");
    //queryString.append("_sort", "-service-date");
    let headers = {};
    let requestInstance = hl7Instance; // Default to CMS instance
    const accessToken = token;
    headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    requestInstance = hl7Instance; // Use HL7 instance for all sources

    // Fetch claims
    try {
      const endpoint = "/Procedure/";
      const response = await requestInstance.get(endpoint, {
        headers: headers,
        params: queryString,
      });
      const data = response.data;
      setClaims(data.entry || []);
      setTotalClaims(data.total || 0);

      // Handle pagination
      const computedPage = Math.floor(((data.entry?.length || 0) - 1) / 10) + 1;
      setCurrentPage(computedPage);
      const nextLink = data.link.find((l) => l.relation === "next");
      const baseURL = requestInstance.defaults.baseURL || "";
      const fullURL = `${baseURL}${endpoint}?${queryString}`;
      setUrlStack([fullURL]);
      if (nextLink) {
        setPaginationUrls({
          current: fullURL,
          next: nextLink.url,
          prev: "",
        });
        setPrevUrls(fullURL);
        setNextUrl(nextLink.url);
      }
       // Fetch provider names only for NPIs on the current page
   
    } catch (error) {
      console.error("Error fetching claims:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedTabKey === "procedure" && memberID) {
      fetchClaims({
        date: searchParams.date,
        text: searchParams.codeText,
      });
    }
   // handleClear();
  }, [selectedTabKey, memberID]);
  const fetchNextPage = async () => {
    try {
      setError(null);
      setLoading(true);

      const response = await hl7Instance.get(paginationUrls.next, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = response.data;
      const prevLink = data.link.find((link) => link.relation === "previous");
      if (prevLink) {
        const prevUrl = prevLink.url;

        setPaginationUrls((prev) => ({
          ...prev,
          prev: prevUrl,
        }));
      }

      setLoading(true);
      const claims =
        data.entry.map((item) => ({
          ...item,
          fullURL: item.fullUrl, // Assuming fullUrl is a property of item
        })) || [];

      setClaims(() => [...claims, ...claims]);
      setLoading(false);

      const nextLink = data.link.find((l) => l.relation === "next");
      setUrlStack((prevUrls) => [...prevUrls, paginationUrls.current]); // push the current URL
      setPaginationUrls({
        current: paginationUrls.next,
        next: nextLink ? nextLink.url : "",
        prev: urlStack[urlStack.length - 1] || "", // Set the previous URL
      });
      setClaims(data.entry || []);
      setCurrentPage((prevPage) => prevPage + 1);
      if (totalClaims === null) {
        setTotalClaims(data.total || 0);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Fetch Next Page Error:", error);
      setError(
        "An error occurred while fetching the next page. Please try again."
      );
    }
  };
  const fetchPreviousPage = async () => {
    try {
      setError(null);
      setLoading(true);

      if (urlStack.length <= 1) return;
      const prevUrl = urlStack[urlStack.length - 1];
      const response = await hl7Instance.get(prevUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = response.data;
      const nextLink = data.link.find((l) => l.relation === "next");

      setUrlStack((prevUrls) => {
        const newUrls = [...prevUrls];
        newUrls.pop();
        return newUrls;
      });

      setPaginationUrls({
        current: prevUrl,
        next: nextLink ? nextLink.url : "",
        prev: urlStack[urlStack.length - 2] || "",
      });

      setClaims(data.entry || []);
      setCurrentPage((prevPage) => prevPage - 1);
      if (totalClaims === null) {
        setTotalClaims(data.total || 0);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Fetch Previous Page Error:", error);
      setError(
        "An error occurred while fetching the previous page. Please try again."
      );
    }
  };

  const handleTextChange = (e) => {
    setSearchParams((prevParams) => ({
      ...prevParams,
      codeText: e.target.value,
    }));
  };

  const handleDateChange = (date) => {
    if (date) {
      const localDate = new Date(date);
      localDate.setHours(0, 0, 0, 0); // Set to midnight to avoid time zone shifting
      const formattedDate = localDate.toLocaleDateString("en-CA"); // 'en-CA' gives YYYY-MM-DD format

      setSearchParams((prevParams) => ({
        ...prevParams,
        date: formattedDate,
      }));
    } else {
      setSearchParams((prevParams) => ({
        ...prevParams,
        date: "", // Clear the date if no date is selected
      }));
    }
  };

  const handleSearch = () => {
    // Ensure that fromDate and toDate are valid Date objects or handle them as needed
    const cleanedText = searchParams.codeText.trim().replace(/\s+/g, " ");
    fetchClaims({
      date: searchParams.date,
      text: cleanedText,
    });
  };
  const handleClear = () => {
    setSearchParams({
      date: "",
      codeText: "",
    });
    fetchClaims();
  };

  const getProviderName = async (npi) => {
    if (npi && npi.length === 10) {
      try {
        const response = await BlueButtonApi.getProviderNameByNpi(npi);
        if (response) {
          return response;
        }
      } catch (error) {
        console.error("Error fetching provider name: ", error);
        return npi; // In case of error, return the NPI value itself
      }
    }
    return npi; // If NPI is invalid (not 10 digits), return the NPI value itself
  };
  
  useEffect(() => {
    const fetchProviderNames = async () => {
      const newProviderNames = { ...providerNames }; // Clone existing provider names
      const npiSet = new Set();
  
      claims.forEach((claim) => {
        const npi = claim?.resource?.performer?.[0]?.actor?.identifier?.value;
        if (npi && npi.length === 10 && !providerNames[npi]) {
          npiSet.add(npi); // Add only missing NPIs
        }
      });
  
      if (npiSet.size === 0) return; // No need to fetch if all NPIs are already in the state
  
      const providerNamePromises = [...npiSet].map(async (npi) => {
        const providerName = await getProviderName(npi);
        newProviderNames[npi] = providerName;
      });
  
      await Promise.all(providerNamePromises); // Fetch all provider names in parallel
  
      setProviderNames(newProviderNames); // Update state with fetched provider names
    };
  
    if (selectedTabKey === "procedure" && memberID) {
      fetchProviderNames();
    }
  }, [ claims, selectedTabKey, memberID]); // Re-run only when claims change
  

  return (
    <>
      <div className="card-form form-content mt-0">
        <div className="row">
          <div className="col-sm-6 col-md-6 col-xl-4 col-xxl-3 mb-4 mb-sm-0">
            <label htmlFor="searchprocedure" className="d-flex form-label">
              Procedure Name
            </label>

            <div className="form-field position-relative">
              <input
                id="searchprocedure"
                type="text"
                className="form-control h-48"
                placeholder="Search for a Procedure"
                value={searchParams.codeText}
                onChange={handleTextChange}
              />
              <span className="field-icon position-absolute top-50 translate-middle-y">
                <i className="fh_search"></i>
              </span>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-xl-3 mb-4 mb-md-4 mb-lg-4 mb-xl-0">
            <label htmlFor="performedDate" className="d-flex form-label">
              Performed Date
            </label>
            <div className="form-field">
              <DatePicker
                className={`form-control h-48`}
                placeholderText="MM / DD / YYYY"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                selected={
                  searchParams.date ? new Date(searchParams.date) : null
                } // Bind date picker to state
                onChange={handleDateChange} // Handle date change
              />
            </div>
          </div>
          <div className="d-flex align-items-sm-end justify-content-center col-12 col-sm-auto">
            <button
              type="button"
              className="btn btn-primary h-48 me-3"
              onClick={handleSearch}
            >
              <span>Search</span>
            </button>
            <button
              type="button"
              className="btn btn-border-primary h-48"
              onClick={handleClear}
            >
              <span>Clear</span>
            </button>
          </div>
        </div>
      </div>
      <div className="card-content">
        <div className="content-subheader d-flex align-items-center">
          <h2 className="page-subheader me-3">Procedure List</h2>
          <span className="total-record">
            [ Total Records : {loading ? 0 : totalClaims} ]
          </span>
        </div>
        <PerfectScrollbar className="table-responsive mb-2">
          <table className="table">
            <thead className="sticky-top z-1">
              <tr>
                <th>Procedure Name</th>
                <th className="m-w-160">Procedure Code</th>
                <th>Status</th>
                <th className="m-w-160">Performed Date</th>
                <th className="m-w-220">Performing Doctor</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={6} className="text-center my-4">
                    <div className="spinner-border" role="status"></div>
                  </td>
                </tr>
              ) : claims && claims.length > 0 ? (
                claims.map((entry, index) => {
                  // You can write additional logic here
                  const displayValue = entry.resource.code.coding[0].display
                    ? entry.resource.code.coding[0].display.replace(
                        /\(.*?\)/g,
                        ""
                      )
                    : "N/A";
                  const codeValue = entry.resource.code.coding[0].code || "N/A";
                  const statusValue =
                    toTitleCase(entry.resource.status) || "N/A";
                  const performedDateTimeValue =
                    formatDateToDDMMYY(entry.resource.performedDateTime) ||
                    "N/A";

                  const npi = toTitleCase(
                    entry.resource.performer[0].actor.identifier.value
                  );
                  const performerValue =
                   toTitleCase(entry.resource.performer[0].actor.display) ||
                    (npi && toTitleCase(providerNames[npi])) ||
                   "-";
                  return (
                    <tr key={index}>
                      <td>{toTitleCase(displayValue)}</td>
                      <td>{codeValue}</td>
                      <td>{statusValue}</td>
                      <td>{performedDateTimeValue}</td>
                      <td>{performerValue}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={6} className="text-center my-4">
                    <p>No Data Found</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </PerfectScrollbar>
        <div className="d-flex justify-content-end">
          <nav
            aria-label="Page navigation"
            className="d-flex align-items-center"
          >
            <>
              <span className="pagnination-info me-2">Page</span>
              <ul className="pagination d-flex mb-0">
                <li className="page-item">
                  <button
                    className="page-link"
                    disabled={currentPage === 1 || !claims.length}
                    onClick={fetchPreviousPage}
                  >
                    <i className="fh_arrow_left_line"></i>
                  </button>
                </li>
                <li className="page-item">
                  <button className="page-link current-page">
                    {currentPage || 1}
                  </button>
                </li>
                <li className="page-item">
                  <button
                    className="page-link me-0"
                    disabled={currentPage >= totalPages}
                    onClick={fetchNextPage}
                  >
                    <i className="fh_arrow_right_line"></i>
                  </button>
                </li>
              </ul>
            </>
          </nav>
        </div>
      </div>
    </>
  );
};

export default ProcedureComponent;
