export const urls = {
  previousInsurance: {
    getPayerbyId: (id: number) => `/PortalPayer/payer/${id}`,
    getPayerConfiguration: (payerID: number) =>
      `/PortalPayer/payers-with-config/${payerID}`,
    getPayersForDropDown: `/PortalPayer/payers/GetPayersForDropdown`,
    updatePayer: `/PortalPayerMember/member`,
    updateDatabse: `/PortalPayerMember/member`,
    updtateBBMember: "/PortalMember/member",
    getBBMember: (ssoguid: any) => `PortalMember/member/${ssoguid}`,
    checkEmailExistence: (email: string) =>
      `/PortalMember/member-by-email?email=${email}`,
    getListData: (
      payerName: string,
      SSOUserID: string,
      page: number,
      pageSize: number,
      sortColumn: string = "ID",
      sortOrder: string = "desc"
    ) =>
      `/PortalPayerMember/search?payerName=${payerName}&SSOUserID=${SSOUserID}&page=${page}&pageSize=${pageSize}&sortColumn=${sortColumn}&sortOrder=${sortOrder}`,
    getRefreshToken: "PortalPayerMember/search",
    getFhirUrl: (payerId: number) =>
      `/PortalPayerFhirRes/search?resourceName=ExplanationOfBenefit&payerID=${payerId}&page=1&pageSize=10&sortColumn=ResourceID&sortOrder=desc`,

    fetchPayerToke: `PortalPayerMember/payerToken`,
  },

  user: {
    createMember: `/PortalMember/member`,
  },
  blueButton: {
    GetBlueButtonPatient: (ssoguid: string) =>`/BlueButton/GetBlueButtonPatient/${ssoguid}`,
    getBluButtonConfigurations:"/BlueButton/GetBlueButtonConfiguration",
    grantBlueButtonAccess:"/BlueButton/grantBlueButtonAccess",
    revokeBlueButtonAccess:"/BlueButton/revokeBlueButtonAccess",
    updateBlueButtonPatient: "/BlueButton/updateBlueButtonPatient",
    getProviderName:(npiNumber:number)=>`/NPI/${npiNumber}`
  },
  clinicalInformation:{
    getProceduralClaims:(id:string,count:number)=>`/Procedure?patient=${id}&_count=${count}`,
    getProblemClaims:(id:string,count:number)=>`/Condition?patient=${id}&_count=${count}`, 
    getCarePlanClaims: (id: string, category: string) => `/CarePlan?patient=${id}&category=${encodeURIComponent(category)}`, 
    getMedicationRequests: (id: string , atex:string) => `/MedicationRequest?patient=${id}&atex=${encodeURIComponent(atex)}`,
     
    
          
  }
};
