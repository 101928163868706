import "./Authorization.scss";
const AuthInfo = () => {
    const authorizationDetails = [
        { icon: 'fh_key_fill', label: 'Authorization ID', value: 'PA123456' },
        { icon: 'fh_profile', label: 'Patient ID', value: '1001' },
        { icon: 'fh_office', label: 'Insurer', value: 'Example Health Plan' },
        { icon: 'fh_Type', label: 'Type', value: 'Institutional' },
        { icon: 'fh_summary', label: 'Associated Claim', value: 'XCLM1001' },
    ];
    return (
        <>
            <div className="card mb-4 w-full">
                <div className="card-body pb-0">
                    <div className="card-content">
                        <div className="content-subheader">
                            <h2 className="page-subheader">Authorization Details</h2>
                        </div>
                        <div className="row">
                            {authorizationDetails.map((item, index) => (
                                <div
                                    key={index}
                                    className="mb-3"
                                >
                                    <div className="label-info-main d-flex align-items-center">
                                        <i className={item.icon}></i>
                                        <div>
                                            <label>{item.label}</label>
                                            <span>{item.value}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AuthInfo;
