import "./Authorization.scss";
const AuthOutcome = () => {
    const outcomeDetails = [
        { icon: 'fh_dot_big', label: 'Status', value: 'Cancelled' },
        { icon: 'fh_open_in_new', label: 'Outcome', value: 'Complete' },
        { icon: 'fh_Adjudication_Reason', label: 'Adjudication Reason', value: 'Plan Limit Reached' },
        { icon: 'fh_dollar_circle_fill', label: 'Eligible Amount', value: '$300' },
    ];

    return (
        <>
            <div className="card mb-4 w-full">
                <div className="card-body pb-0">
                    <div className="card-content">
                        <div className="content-subheader">
                            <h2 className="page-subheader">Outcome & Adjudication Reason</h2>
                        </div>
                        <div className="row">
                            {outcomeDetails.map((item, index) => (
                                <div
                                    key={index}
                                    className="mb-3"
                                >
                                    <div className="label-info-main d-flex align-items-center">
                                        <i className={item.icon}></i>
                                        <div>
                                            <label>{item.label}</label>
                                            <span>{item.value}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default AuthOutcome;
