import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { getEnvVariable } from "../environments/env";

export class ClinicalInfo {
  private static axios = axios.create({
    baseURL: process.env.REACT_APP_CLINICAL_INSTANCE,
    headers: {
      "Content-Type": "application/json",
    },
  });

  static async get(url: string,params?: any, config?: AxiosRequestConfig) {
    try {
      const token = localStorage.getItem('PortalAdmin-AccessToken')
    const headers = {
        authorization: token ? `Bearer ${token}` : '',
        ClientId: process.env.REACT_APP_PAYER_ID, 
        ...(config?.headers || {})
      };
      const updatedConfig: AxiosRequestConfig = { ...config, headers,params };
      const response = await ClinicalInfo.axios.get(url, updatedConfig);
      return response.data;
    } catch (e) {
      return ClinicalInfo.handleErrors(e);
    }
  }

  private static handleErrors(error: any) {
    if (error) {
      const errorMessage =
        error||
        "Something Went Wrong. Please Try Again";
       //alert(errorMessage)
    } else {
    // alert("Something went wrong. Please try again.");
    }
  }
}