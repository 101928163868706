import { useEffect, useState, useRef } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";
import { hl7Instance } from "../../api/axios";
import { toTitleCase, formatDateToDDMMYY } from "../../utils/Utils";

const CarePlan = ({ selectedTabKey }) => {
  const { memberID } = useSelector(
    (state) => state?.clinicalInfo?.memberData || {}
  );
  const token = localStorage.getItem("PortalAdmin-AccessToken");

  const ITEMS_PER_PAGE = 10;
  const [loading, setLoading] = useState(false);
  const [totalCareplan, setTotalCareplan] = useState(0);
  const [categoryQuery, setCategoryQuery] = useState("");
  const [providerQuery, setProviderQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [urlStack, setUrlStack] = useState([]);
  const [paginationUrls, setPaginationUrls] = useState({
    current: "",
    next: "",
    prev: "",
  });

  // Create refs for queries
  const categoryQueryRef = useRef(categoryQuery);
  const providerQueryRef = useRef(providerQuery);

  // Sync refs with state
  useEffect(() => {
    categoryQueryRef.current = categoryQuery.trim().toLowerCase();
  }, [categoryQuery]);

  useEffect(() => {
    providerQueryRef.current = providerQuery.trim().toLowerCase();
  }, [providerQuery]);

  const applyFilters = (entries) => {
    return entries.filter((item) => {
      // Trim and lowercase ALL values
      const provider = (item.resource?.author?.display || "")
        .toLowerCase()
        .trim();
      const category =
        item.resource?.category?.flatMap(
          (cat) =>
            cat.coding?.map((code) => (code.code || "").toLowerCase().trim()) ||
            []
        ) || [];

      // Get trimmed queries from refs
      const currentCategory = categoryQueryRef.current;
      const currentProvider = providerQueryRef.current;

      const categoryMatch =
        !currentCategory ||
        category.some((code) => code.includes(currentCategory));

      const providerMatch =
        !currentProvider || provider.includes(currentProvider);

      return categoryMatch && providerMatch;
    });
  };

  const fetchCarePlan = async (url) => {
    setLoading(true);
    setFilteredData([]);
    setTotalCareplan(0);

    try {
      const response = await hl7Instance.get(url || "/CarePlan/", {
        headers: { Authorization: `Bearer ${token}` },
        params: { patient: memberID, _count: ITEMS_PER_PAGE },
      });

      const data = response.data;
      const entries = data.entry || [];

      // Use ref values to determine filtering
      if (!categoryQueryRef.current && !providerQueryRef.current) {
        setFilteredData(entries);
        setTotalCareplan(entries.length);
      } else {
        const filteredEntries = applyFilters(entries);
        setFilteredData(filteredEntries);
        setTotalCareplan(filteredEntries.length);
      }

      // Handle pagination
      const nextLink = data.link?.find((l) => l.relation === "next");
      const prevLink = data.link?.find((l) => l.relation === "previous");

      setPaginationUrls({
        current: url || "/CarePlan/",
        next: nextLink?.url || "",
        prev: prevLink?.url || "",
      });

      if (url) setUrlStack(["/CarePlan/"]);
    } catch (error) {
      console.error("Error fetching CarePlan:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedTabKey === "carePlan" && memberID) {
      setCurrentPage(1);
      fetchCarePlan();
    }
  }, [selectedTabKey, memberID]);
  const handleSearch = async () => {
    setCurrentPage(1);
    // Force trim before search
    setCategoryQuery((prev) => prev.trim());
    setProviderQuery((prev) => prev.trim());
    fetchCarePlan();
  };

  const handleClear = async () => {
    // Reset both state and refs
    setCategoryQuery("");
    setProviderQuery("");
    categoryQueryRef.current = "";
    providerQueryRef.current = "";

    setCurrentPage(1);
    setPaginationUrls({ current: "/CarePlan/", next: "", prev: "" });
    await fetchCarePlan();
  };

  const fetchNextPage = async () => {
    if (!paginationUrls.next) return;
    setLoading(true);
    try {
      const response = await hl7Instance.get(paginationUrls.next, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const data = response.data;
      const entries = data.entry || [];
      const filteredEntries = applyFilters(entries);

      setUrlStack((prev) => [...prev, paginationUrls.current]);
      setPaginationUrls({
        current: paginationUrls.next,
        next: data.link?.find((l) => l.relation === "next")?.url || "",
        prev: paginationUrls.current,
      });

      setFilteredData(filteredEntries);
      setCurrentPage((p) => p + 1);
    } catch (error) {
      console.error("Fetch Next Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchPreviousPage = async () => {
    if (urlStack.length <= 1) return;
    setLoading(true);
    try {
      const prevUrl = urlStack[urlStack.length - 1];
      const response = await hl7Instance.get(prevUrl, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const data = response.data;
      const entries = data.entry || [];
      const filteredEntries = applyFilters(entries);

      setUrlStack((prev) => prev.slice(0, -1));
      setPaginationUrls({
        current: prevUrl,
        next: data.link?.find((l) => l.relation === "next")?.url,
        prev: urlStack[urlStack.length - 2] || "",
      });

      setFilteredData(filteredEntries);
      setCurrentPage((p) => p - 1);
    } catch (error) {
      console.error("Fetch Previous Error:", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <div className="card-form form-content mt-0">
        <div className="row">
          <div className="col-sm-6 col-md-6 col-xl-4 col-xxl-3 mb-4 mb-sm-0">
            <label htmlFor="carePlan" className="d-flex form-label">
              Care Plan Category
            </label>
            <div className="form-field position-relative">
              <input
                id="carePlan"
                type="text"
                className="form-control h-48"
                placeholder="Search For A category"
                value={categoryQuery}
                onChange={(e) => setCategoryQuery(e.target.value)}
                onBlur={() => setCategoryQuery((prev) => prev.trim())}
              />
              <span className="field-icon position-absolute top-50 translate-middle-y">
                <i className="fh_search"></i>
              </span>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-xl-3 mb-4 mb-md-4 mb-lg-4 mb-xl-0">
            <label htmlFor="providerName" className="d-flex form-label">
              Provider Name
            </label>
            <div className="form-field position-relative">
              <input
                id="providerName"
                type="text"
                className="form-control h-48"
                placeholder="Search For A provider"
                value={providerQuery}
                onChange={(e) => setProviderQuery(e.target.value)}
                onBlur={() => setProviderQuery((prev) => prev.trim())}
              />
              <span className="field-icon position-absolute top-50 translate-middle-y">
                <i className="fh_search"></i>
              </span>
            </div>
          </div>
          <div className="d-flex align-items-sm-end justify-content-center col-12 col-sm-auto">
            <button
              type="button"
              className="btn btn-primary h-48 me-3"
              onClick={handleSearch}
              disabled={loading}
            >
              <span>Search</span>
            </button>
            <button
              type="button"
              className="btn btn-border-primary h-48"
              onClick={handleClear}
              disabled={loading}
            >
              Clear
            </button>
          </div>
        </div>
      </div>
      <div className="card-content">
        <div className="content-subheader d-flex align-items-center">
          <h2 className="page-subheader me-3">Care Plan List</h2>
          <span className="total-record">
            [ Total Records : {totalCareplan} ]
          </span>
        </div>
        <PerfectScrollbar className="table-responsive mb-2">
          <table className="table">
            <thead className="sticky-top">
              <tr>
                <th>Care Plan Category</th>
                <th>Description</th>
                <th>Plan Created Date</th>
                <th>Provider Name</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="4" className="text-center my-4">
                    <div className="spinner-border" role="status"></div>
                  </td>
                </tr>
              ) : filteredData.length > 0 ? (
                filteredData.map((entry, index) => (
                  <tr key={index}>
                    <td>
                      {toTitleCase(
                        entry.resource.category?.[0]?.coding?.[0]?.code
                      ) || "N/A"}
                    </td>
                    <td>{entry.resource.description || "N/A"}</td>
                    <td>{formatDateToDDMMYY(entry.resource.created)}</td>
                    <td>{entry.resource.author?.display || "N/A"}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="text-center">
                    No Data Available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </PerfectScrollbar>
        <div className="d-flex justify-content-end">
          <nav
            aria-label="Page navigation"
            className="d-flex align-items-center"
          >
            {<span className="pagination-info me-2">Page</span>}
            <ul className="pagination d-flex mb-0">
              <li className="page-item">
                <button
                  className="page-link"
                  disabled={currentPage === 1 || loading}
                  onClick={fetchPreviousPage}
                >
                  <i className="fh_arrow_left_line"></i>
                </button>
              </li>
              <li className="page-item">
                <button className="page-link current-page">
                  {currentPage}
                </button>
              </li>
              <li className="page-item">
                <button
                  className="page-link me-0"
                  disabled={
                    currentPage >= Math.ceil(totalCareplan / ITEMS_PER_PAGE) ||
                    loading ||
                    !paginationUrls.next
                  }
                  onClick={fetchNextPage}
                >
                  <i className="fh_arrow_right_line"></i>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};
export default CarePlan;
