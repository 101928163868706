import { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import ProblemComponent from "../clinical-information/problem-component";
import ProcedureComponent from "../clinical-information/procedure-component";
import AllergyIntolerance from "../clinical-information/allergy-intolerance";
import CareTeam from "../clinical-information/care-team";
import CarePlan from "../clinical-information/care-plan";
import Immunization from "../clinical-information/immunization";
import MedicationRequest from "../clinical-information/medication-request";
import GoalComponent from "../clinical-information/goal-component";
import { getPatientDetails } from "../../redux/slice/ClinicalInforSlice";
import { useDispatch } from "react-redux";

const ClinicalInformation = () => {
  const [key, setKey] = useState("problems");
  const ssoGuid = localStorage.getItem("userSub");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPatientDetails(ssoGuid));
  }, []);
  return (
    <>
      <div className="main-content">
        <div className="content-header">
          <h1 className="page-header">Clinical Information</h1>
        </div>
        <div className="card">
          <div className="card-body custom-tab">
            <Tabs activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
              <Tab eventKey="problems" title="Problems">
                <ProblemComponent selectedTabKey={key} />
              </Tab>
              <Tab eventKey="procedure" title="Procedure">
                <ProcedureComponent selectedTabKey={key} />
              </Tab>
              <Tab eventKey="allergy" title="Allergy Intolerance">
                <AllergyIntolerance />
              </Tab>
              <Tab eventKey="careteam" title="Care Team">
                <CareTeam  selectedTabKey={key}  />
              </Tab>
              <Tab eventKey="carePlan" title="Care Plan">
                <CarePlan selectedTabKey={key}/>
              </Tab>
              <Tab eventKey="goal" title="Goal">
                <GoalComponent />
              </Tab>
              <Tab eventKey="immunization" title="Immunization">
                <Immunization />
              </Tab>
              <Tab eventKey="medicationRequest" title="Medication Request">
                <MedicationRequest  selectedTabKey={key}/>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClinicalInformation;
