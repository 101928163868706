import "./Authorization.scss";

const AuthService = () => {
    return (
        <div className="card mb-4 w-full">
            <div className="card-body">
                <>
                    <div className="card-content">
                        <div className="content-subheader">
                            <h2 className="page-subheader">Auth Service</h2>
                        </div>
                        <div className="table-responsive">
                            <table className="table mb-0">
                                <thead>
                                    <tr>
                                        <th className="m-w-190">Prior Authorization ID</th>
                                        <th className="m-w-300">Procedure</th>
                                        <th className="m-w-220">Service Type</th>
                                        <th className="m-w-170">Submitted Amount</th>
                                        <th className="m-w-180">Adjudication Status</th>
                                        <th className="m-w-190">Adjudication Reason</th>
                                        <th className="m-w-160">Adjudication Date</th>
                                        <th className="m-w-160">Eligiblity Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>PA123456</td>
                                        <td>99213 - Office Visit, Established Patient</td>
                                        <td>38 - Orthodontics</td>
                                        <td className="fw-bold">$150</td>
                                        <td>Approved</td>
                                        <td>Plan Limit Reached</td>
                                        <td>1/7/2024</td>
                                        <td className="fw-bold">$120</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>
            </div>
        </div>
    );
};

export default AuthService;
